<template>
  <section class="block-main main" :class="device_platform">
    <div class="main__container container">
      <div class="main__content">
        <h1 class="main__title">НОЯБРЬСКИЕ СКИДКИ В РОЛЬФ</h1>
        <h2 class="main__subtitle">РАСПРОДАЖА LEXUS В НАЛИЧИИ</h2>
        <!-- <h1 class="main__title">Весенняя распродажа<br> lexus в рольф</h1>
        <h2 class="main__subtitle">Акция до {{ lastStringDate }}</h2>
        <p class="main__text">ВЫГОДА ДО 1 400 000 ₽</p> -->
      </div>
      <button
        class="main__button button button_theme_secondary"
        @click="
          getCall({ type: 'sale', form: 'main', title: 'УЗНАТЬ ЦЕНУ' })
        "
      >
      УЗНАТЬ ЦЕНУ
      </button>
      <!-- <img 
        v-if="device_platform !== 'mobile'" 
        src="@/images/badges/go.svg" 
        class="main__badge"
      > -->
    </div>
    <!-- <div class="main__video-wrapper">
      <video class="main__video-file" loop muted autoplay playsinline>
        <source src='../video/lexus.mp4' type="video/mp4">
      </video>
    </div> -->
    <!-- <img src="@/images/temporary/heart1.png" class="main__temporary"> -->
  </section>
</template>

<script>
import Mixin from "../common/mixin";
import finance from "../common/finance";
import Swiper from "swiper";
import $ from "jquery";

export default {
  name: "block-main",
  components: {},
  mixins: [Mixin, finance],
  data() {
    
  const today = new Date()
  let lastDayAction = new Date(today.getFullYear() , today.getMonth() ,today.getDate() +3);
  
  if(
    Number(today.getDate()) <  16 &&
    Number(today.getMonth()) === 3 &&
    Number(today.getFullYear()) === 2024
  ){
    lastDayAction = new Date(today.getFullYear() , today.getMonth() ,15);
  }

  const lastStringDate = lastDayAction.toLocaleDateString("ru",{month: 'long', day: 'numeric'});

    return {
      swiper: null,
      array_of_slides: [],
      lastStringDate: lastStringDate,
    };
  },
  mounted() {
    // this.initSwiper();
  },
  watch: {
    device_platform() {
      if (this.swiper) {
        this.swiper.destroy();
        this.swiper = null;
        this.$nextTick(this.initSwiper);
      }
    },
  },
  methods: {
    scrollTo(where) {
      this.$emit("scrollTo", where);
    },
    getAgreement() {
      this.$emit("getAgreement");
    },
    initSwiper() {
      if (this.swiper) return;
      this.swiper = new Swiper(".swiper-container-main", {
        loop: this.array_of_slides.length > 1,
        simulateTouch: false,
        autoplay:
          this.array_of_slides.length > 1
            ? {
                delay: 5000,
              }
            : false,
        navigation: {
          nextEl: ".arrow-right",
          prevEl: ".arrow-left",
        },
      });

      if (this.swiper.loopedSlides) {
        //повесить маску на input в дубликате
        let dublicate = $(".swiper-slide-duplicate"),
          dublicate_inputs = dublicate.find("input[type=tel]");

        dublicate_inputs.each((index) => {
          let el = dublicate_inputs.get(index),
            elem = $(el);

          Inputmask({ mask: "(+7|8) (999) 999-9999" }).mask(el);
          elem
            .on("focus", () => {
              if (!el.value.length) {
                setTimeout(() => {
                  el.setSelectionRange(4, 4);
                }, 0);
              }
            })
            .on("blur", () => {
              if (el.value.length < 5) {
                el.value = "";
              }
            });
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.main {
  position: relative;
  width: 100%;
  background: url("../images/background/bg-new.jpg") no-repeat center 75% / cover;
  z-index: 5;
  height: calc(100vw * 700 / 1920);
  min-height: 567px;

  &__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    padding-left: 100px;
    color: #fff;
  }

  &__title {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &__text {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 20px;
  }

  &__button {
    margin-top: 15px;
    height: 60px;
    width: 300px;
    max-width: 100%;
    font-size: 20px;
    text-align: center;
    padding: 0;
  }

  &__button-mobile {
    display: none;
  }

  &__badge {
    width: 250px;
    margin-top: 60px;
  }

  &__content {
    position: relative;
    // background: rgba(0, 0, 0, 0.6);
    // padding: 10px;
  }

  &__video-wrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5) center/cover;
  }

  &__video-file {
    width: 100%;
  }

  // &__temporary {
  //   position: absolute;
  //   bottom: -60px;
  //   left: 95px;
  // }
}

@media (max-width: 1000px) {
  .main {
    &__title {
      font-size: 3vw;
    }
  }
}

@media (max-width: 1302px) {
  .main {
    background:linear-gradient(180deg, #000 0%, transparent 40%) ,  url("../images/background/bg-new.jpg") no-repeat 80% 50% /cover;

    &__badge {
      
      margin-top: 10px;
    }
  }
}

@media (max-width: 1302px) {
  .main {

    &__container {
      padding-left: 30px;
    }

    &__title {
      font-size: 38px;
      line-height: calcFontSize(48, 72, 640, 1920);
    }

    &__subtitle,
    &__text {
      font-size: 28px;
      line-height: calcFontSize(28, 72, 640, 1920);
    }
  }
}

@media (max-width: 660px) {
  .main {
    margin-top: 125px;
    height: calc(100vw * 700 / 600);
    background:  url("../images/background/bg-new_m.jpg") no-repeat 70% 50%/cover;
   //height: 200vw;
   // height: 450px;
    // background: url("../images/background/bg.jpg") no-repeat center/cover;
    // background-position: center bottom;
    // background-position: 90% 50%;
    // background-position: calcFontSize(-550, -500, 320, 640) 50%;
    min-height: unset;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      row-gap: 10px;
      padding: 20px 15px;
      height: 100%;
    }

    &__title {
      width: 100%;
      // text-align: center;
      font-size: calcFontSize(20, 38, 320, 640);
      line-height: 1.5;
      padding: 0;
      // text-shadow: none;
      // background: none;
      // border-top: 2px solid #fff;
      // border-bottom: 2px solid #fff;
    }

    &__subtitle,
    &__text {
      font-size: 18px;
      margin: 5px;
    }

    &__button {
      order: 5;
      margin-top: 0;
      height: 50px;
      font-size: 16px;
    }

    &__button-mobile {
      position: absolute;
      bottom: 30px;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0);
      width: 90%;
      height: 60px;
      display: block;
    }

    &__badge {
      width: 160px;
      margin-top: 0px;
    }

    &__video-file {
      width: auto;
      height: 140%;
    }

    // &__temporary {
    //   width: 60px;
    //   bottom: -30px;
    // }
  }

  .no-mobile {
    display: none;
  }
}

// @media (max-width: 450px) {
//   .main {
//     &__title {
//       font-size: 26px;
//     }
//   }
// }
</style>
<style lang="scss">
.main {
  &__button:before,
  &__button:after {
      display: none;
  }
}
</style>
