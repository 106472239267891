const NOW = new Date();
const CURRENT_DAY = NOW.getDate();
const CURRENT_MONTH = NOW.getMonth();
const CURRENT_YEAR = NOW.getFullYear();
const CURRENT_WEEKDAY = NOW.getDay();

const FORMAT_OPTIONS = {
  number: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  },
  shortNumber: {
    day: '2-digit',
    month: '2-digit'
  },
  string: {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  },
  shortString: {
    day: '2-digit',
    month: 'long'
  }
};

const MONTHS = {
  default: [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь'
  ],
  'а-я': [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря'
  ],
  'у-ю': [
    'январю',
    'февралю',
    'марту',
    'апрелю',
    'маю',
    'июню',
    'июлю',
    'августу',
    'сентябрю',
    'октябрю',
    'ноябрю',
    'декабрю'
  ],
  е: [
    'январе',
    'феврале',
    'марте',
    'апреле',
    'мае',
    'июне',
    'июле',
    'августе',
    'сентябре',
    'октябре',
    'ноябре',
    'декабре'
  ]
};

export {
  NOW,
  CURRENT_DAY,
  CURRENT_MONTH,
  CURRENT_YEAR,
  CURRENT_WEEKDAY,
  MONTHS,
  FORMAT_OPTIONS
};
