<template>
  <div
    class="block-legal legal"
    :class="[device_platform]"
  >
    <div class="legal__container container">
      <p>
        Политика АО «РОЛЬФ» в области обработки и обеспечения безопасности
        персональных данных
      </p>
      <div class="legal__block-icons">
        <img
            src="../images/labels/rating.png"
            alt="Рейтинг в Яндекс - 4.9/5"
            v-if="device_platform === 'desktop'"
          />
          <img
            class="legal__award"
            src="../images/logos/logo-award.svg"
            alt="Автодилер года 2021"
            v-if="device_platform === 'desktop'"
          />
      </div>
      <p>ИНН: 7715586594 ОГРН: 1057749487399 <br />ООО «РОЛЬФ МОТОРС»</p>
      <button
        class="legal__btn"
        @click="expanded = !expanded"
      >
        Подробные условия акции
      </button>

      <transition name="slide">
        <div
          class="legal__text"
          v-show="expanded"
        >
          <br /><br />Текст соглашения на обработку: Метод обработки звонков: С
          01 по 31 декабря 2023 года включительно указанная цена является
          максимальной ценой перепродажи (МЦП) в отношении автомобиля (а/м)
          Lexus LS 350 в комплектации Comfort(Комфорт) в цвете «неметаллик»
          (период производства - с 01 апреля 2021 года и далее) и не является
          ценой продажи, устанавливаемой официальным Дилером Lexus. В
          зависимости от комплектации а/м МЦП может повышаться. Сведения о цене
          носят исключительно информационный характер, не являются офертой, в
          том числе публичной (статьи 435, 437 ГК РФ) и не являются
          исчерпывающими. Условия договоров, в рамках которых приобретаются а/м
          (в т.ч цена продажи), устанавливаются официальными Дилерами Lexus.
          Подробности о наличии а/м (в т.ч. конкретных комплектаций), их цене, а
          также о возможности участия в различных акциях и кампаниях уточняйте в
          салонах официальных Дилеров Lexus.
          <br><br>
          Филиал РОСБАНК Авто Публичного акционерного общества РОСБАНК, Генеральная лицензия Банка России №2272 от
          28.01.2015 года.<br><br>
          Ставка по кредиту 10,5% на срок кредита от 60, 84 и 96 месяцев при оформлении автокредита по выделенным тарифам с
          КАСКО. Предложение распространяется на новые автомобили и автомобили с пробегом с первоначальным взносом от 20% и
          более. Ставка 10,5% при применении опции «назначь свою ставку» (НСС). Стоимость НСС – 2,2% в год. Обеспечение по кредиту
          - залог приобретаемого автомобиля. Условия и тарифы могут быть изменены банком в одностороннем порядке. Банк в праве
          отказать в выдаче автокредита. При отсутствии НСС применяется базовая ставка 22,49% на весь срок кредита.
          Полная стоимость потребительского кредита (ПСК) в % годовых составляет от 13,812 до 14,094 при расчетной сумме кредита 3
          млн. рублей.<br><br>
          Требования к заемщику:<br>
          • гражданство РФ
          • регистрация и постоянное проживание в регионе присутствия Росбанк Авто
          • возраст: от 23 до 70 лет на момент погашения кредита
          • официальное трудоустройство на территории РФ
          • непрерывный трудовой стаж на последнем месте работы — не менее 3 месяцев (при оформлении кредита на новый
          автомобиль)
          • непрерывный трудовой стаж на последнем месте работы — не менее 4 месяцев
          • предоставление не менее 2 контактных телефонов<br><br>
          Требования к автомобилю:<br>
          Максимальная сумма кредита: 12 000 000 руб., кроме подержанных автомобилей марок: ГАЗ, УАЗ, ВАЗ, ВИС, ИЖ, Богдан,
          кредитуемых по тарифам Standard до 3 500 000 руб.
          Минимальная сумма кредита: 100 000 руб.
        </div>
      </transition>
    </div>

    <!-- <img src="../images/temporary/footer_present.png" alt="" class="legal__snow"> -->
  </div>
</template>

<script>
import Mixin from "../common/mixin";

export default {
  name: "block-legal",
  mixins: [Mixin],
  data() {
    let today = new Date();
    return {
      expanded: false,
      c_date:
        this.getLastDayOfMonth(today.getFullYear(), today.getMonth()) +
        "." +
        (+today.getMonth() + 1 < 10 ? "0" : "") +
        (today.getMonth() + 1) +
        "." +
        today.getFullYear(),
      year: today.getFullYear(),
    };
  },
  methods: {
    getLastDayOfMonth(year, month) {
      let date = new Date(year, month + 1, 0);
      return date.getDate();
    },
    scrollTo(where) {
      this.$emit("scrollTo", where);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.legal {
  width: 100%;
  padding: 30px 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 18px;
  font-size: 13px;
  background-color: #fefefe;
  color: #000000;
  text-align: center;
  position: relative;

  &__block-icons{
    position: absolute;
    left: 2%;
    top: 5%;

    img{
      display: block;
    }
  }


  &__award {
    width: 100%;
    max-width: 150px;
  }


  &__btn {
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: normal;
    color: #7b7b7b;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 5px;
  }

  &__text {
    color: #000;
    font-size: 12px;
    line-height: 12px;
    text-align: start;
    max-width: 1200px;
    margin: 30px auto;
  }
}

.mobile {
  .legal {
    &__title {
      font-size: 16px;
      @media (max-width: 485px) {
        font-size: 14px;
      }
    }

    &_text {
      font-size: 14px;
    }
  }
}

// Transition
.slide-enter-active {
  transition: all 0.3s ease;
}

.slide-leave-active {
  transition: all 0.3s;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
