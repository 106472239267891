<template>
	<label class="checkbox" @click="change">
		<input type="checkbox" class="checkbox__input" checked />
		<span class="checkbox__box"></span>
		<slot></slot>
	</label>
</template>

<script>
export default {
	name: "CheckBox",
	props: ["value"],
	methods: {
		change() {
			if (!Array.isArray(this.value)) {
				this.$emit("input", !this.value);
			} else {
				let index = this.value.indexOf(this.$attrs.value);
				if (index >= 0) {
					this.$emit(
						"input",
						this.value.filter((item) => item != this.$attrs.value)
					);
				} else {
					let ret = this.value.map((e) => e);
					ret.push(this.$attrs.value);
					this.$emit("input", ret);
				}
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.checkbox {
	display: flex;
	white-space: nowrap;
	align-items: center;
	cursor: pointer;

	&__input {
		position: absolute;
		appearance: none;
		-webkit-appearance: none;

		&:focus + .checkbox__box {
			border: 1px solid red;
		}
	}

	&__input:checked + .checkbox__box::after {
		background-image: url("../images/utils/galka.svg");
		background-size: contain;
		background-repeat: no-repeat;
		width: 17px;
		height: 13px;
		position: absolute;
		left: 1px;
		top: -1px;
		content: "";
		display: block;
	}

	&__box {
		position: absolute;
		width: 15px;
		height: 15px;
		border: 1px solid #e0e0e0;
		background-color: #fff;
	}
}
</style>
