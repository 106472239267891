import CarsInfo from "../common/cars-info";

const PHONE = "+7 (495) 777-77-13"
	.replace(/\s/g, "\u00A0")
	.replace(/-/g, "\u2011");

const MAX_ADVANTAGE = (function () {
	let info = CarsInfo.CARS;
	let max = info[0].advantage;
	for (let i = 0; i < info.length; i++) {
		if (info[i].advantage > max) {
			max = info[i].advantage;
		}
	}
	return max;
})();
const MIN_CREDIT = (function () {
	let info = CarsInfo.CARS;
	let min = info[0].credit_payment;
	for (let i = 0; i < info.length; i++) {
		if (info[i].credit_payment < min) {
			min = info[i].credit_payment;
		}
	}
	return min;
})();

const PHONE_RAW = PHONE.replace(/\D+/g, "");

export default {
	offices: [
		{
			address: "г. Москва, Остаповский пр. 4",
			y: 55.721214,
			x: 37.701718,
		},
	],
	phone: PHONE,
	phone_raw: PHONE_RAW,
	max_advantage: MAX_ADVANTAGE,
	min_credit: MIN_CREDIT,
	worktime: "9:00 до 22:00",
	coords: {
		y_center: 55.721214,
		x_center: 37.701718,
	},

	end_date: null,
	salon_name: "Nissan",
	teh_name: "Nissan",
	need_agreement: true,
	cKeeper: undefined,
};

// [55.76309371185999,37.622504000000006]
