<template>
  <button
    :disabled="disabled"
    :type="type"
    :class="[btn_class, { button_disabled: disabled }]"
    @click="handleClick"
  >
    <span :class="{ 'w100': onlyText }">
      <slot></slot>
    </span>
    <i v-if="!onlyText"></i>
  </button>
</template>

<script>
export default {
  name: "btn",
  props: ["theme", "disabled", "type", "onlyText"],
  computed: {
    btn_class() {
      return this.theme || "button_theme_primary";
    },
  },
  methods: {
    handleClick() {
      this.$emit("handleClick");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>