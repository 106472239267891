<template>
	<div class="stock-table">
		<div class="stock-table__body">
			<div class="stock-table__table">
				<div
					class="stock-table__item"
					v-for="car in cars"
					:key="car.name + car.compl + car.price + car.engine"
				>
					<div class="stock-table__row">
						<div class="stock-table__col">
							<div class="stock-table__car-img">
								<img class="stock-table__img" :src="car.img" :alt="car.name" />
							</div>
						</div>

						<div class="stock-table__col">{{ car.name }}</div>
						<div class="stock-table__col">{{ car.compl }}</div>
						<div class="stock-table__col" v-if="device_platform === 'desktop'">
							{{ car.engine }}
						</div>

						<!--          <div class="stock-table__col">-->
						<!--            <button-->
						<!--              class="stock-table__btn button button-dark-blue"-->
						<!--              @click="$emit('call', 'complectation_price')"-->
						<!--            >-->
						<!--              <template v-if="car.price">-->
						<!--                от <span class="line-through">{{ car.price }}</span>-->
						<!--              </template>-->
						<!--              <template v-else> УТОЧНИТЬ ЦЕНУ </template>-->
						<!--            </button>-->
						<!--          </div>-->

						<div class="stock-table__col">
							<button
								v-if="car.options.length"
								class="stock-table__btn button button_theme_primary"
								v-on:click="status = !status"
								@click="car.opt_show = !car.opt_show"
							>
								Опции
							</button>
						</div>
						<div class="stock-table__col">
							<button
								class="stock-table__btn button button_theme_primary"
								@click="$emit('call', 'complectation_bron')"
							>
								<!-- Цена от <strike> {{ car.price }}</strike>  -->
								Узнать цену
							</button>
						</div>
					</div>
					<div
						v-if="car.opt_show"
						class="card__features" disflex
						@click="status = !status"
					>
						<div class="card__features-list">
							<div class="card__features-close t-modal-close"></div>
							<div class="card__features-text">
								ОПЦИИ <br />{{ car.name }} {{ car.compl }} ({{ car.engine }})<br/>
                <span v-if="car.opt_title">{{ car.opt_title }}</span>
							</div>
							<div class="card__features-table">
								<div
									v-for="option in car.options"
									class="card__features-item"
									v-html="option"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="stock-table__footer">
			<button
				class="stock-table__more-button button button_theme_primary"
				@click="addCount"
				v-if="data.length > 5 && count < data.length"
			>
				Показать ещё
			</button>
		</div>
	</div>
</template>

<script>
	import Mixin from "../common/mixin";
	import Button from "./button.vue";

	const INITIAL_COUNT = 5;
	export default {
		name: "stock-table",
		components: [Button],
		props: ["data"],
		mixins: [Mixin],
		data() {
			return {
				count: INITIAL_COUNT,
				status: true,
				id_car: 0,
				
			};
		},
		watch: {
			data() {
				this.count = INITIAL_COUNT;
			},
		},
		computed: {
			cars() {
				return this.data.slice(0, this.count);
			},
			// card__features: function() {
			// 	if (this.status === true) {
			// 		return "card__features disnone";
			// 	} else {
			// 		return "card__features disflex";
			// 	}
			// },
		},
		methods: {
			addCount() {
				this.count += 10;
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "../styles/constants.scss";

	.stock-table {
		&__table {
			padding: 29px 0;
			// background: #C8CFD8;
		}
		&__img {
			width: 220px;
		}

		&__row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 7.5px 20px;
		}

		&__item,
		&__col {
			flex: 1;
			font-weight: bold;
			font-size: 17px;
			margin-right: 20px;

			.line-through {
				text-decoration: line-through;
			}
		}

		&__col {
			&:last-child {
				flex-basis: fit-content;
				max-width: 240px;
				margin-right: 0;
			}
		}

		&__btn {
			width: 100%;
			display: block;
			font-weight: 700;
			text-transform: inherit;
			font-size: 17px;
		}

		&__footer {
			display: flex;
			justify-content: center;
		}

		&__btn {
			padding: 12px 20px;
		}

		&__more-button {
			padding: 14px 30px;
			max-width: 250px;
			width: 100%;
			margin-top: 20px;
			font-weight: 700;
			justify-content: center;
		}
	}

	@media (max-width: 1200px) {
		.stock-table {
			&__col {
				text-align: center;

				//  &:last-child {
				// 	display: none;
				// }
			}
      
			&__col:has(&__btn) {
				flex-basis: fit-content;
			}
		}
	}

	.card__features {
		align-items: center;
		justify-content: center;
		width: 100%;

		// height: 100vh;
		// position: fixed;
		// z-index: 100;
		// top: 0;
		// left: 0;
		// right: 0;
		// bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		&-text {
			text-align: left;
			margin-bottom: 10px;
		}
	}

	// .disnone {
	// 	display: none;
	// }
	.disflex {
		display: flex;
	}
	.card__features-list {
		width: 100%;
		position: relative;
		padding: 40px;
		background: #fff;
		border-radius: 5px;
		overflow: auto;
		max-height: 95vh;
	}

	.t-modal-close {
		height: 22px;
		width: 22px;
		margin-left: auto;
		cursor: pointer;
		background: url("../images/utils/close.svg") no-repeat center/contain;
	}

	.card__features-table {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.card__features-item {
		display: flex;
		align-items: center;
		padding-top: 10px;
	}

	@media (max-width: 900px) {
		.card__features-item {
			font-size: 14px;
		}

		.card__features-text {
			font-size: 14px;
		}
	}

	.card__features-item::before {
		content: "";
		min-width: 4px;
		min-height: 4px;
		border-radius: 50%;
		background: #001a37;
		margin-right: 5px;
	}
</style>
