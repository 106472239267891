<template>
  <section class="block-cta">
    <div class="block-cta__container container">
      <img :src="bg" alt="Владелец Lexus?">
      <div class="block-cta__content">
        <h2 class="block-cta__title">Владелец Lexus?</h2>
        <div class="block-cta__description">
          <img :src="icon" alt="Для вас специальные условия на сервисное обслуживание">
          <span>
            Для вас <strong>специальные условия</strong><br>на сервисное обслуживание
          </span>
        </div>
        <button
          class="block-cta__btn button button_theme_secondary"
          @click="
            getCall({ type: 'sale', form: 'cta' })
          "
        >
          Узнать подробнее
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import Mixin from "../common/mixin";

const bg = require("../images/background/cta.jpg");
const icon = require("../images/icons/service.svg");

export default {
  name: "block-cta",
  mixins: [Mixin],
  data() {
    return {
      bg,
      icon
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";

.block-cta {
  margin-bottom: 100px;
  
  &__container {
    position: relative;
  }

  &__content {
    position: absolute;
    top: 40px;
    left: -65px;
    background-color: $black;
    padding: 45px 50px;
    width: 100%;
    max-width: 570px;
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    
    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $white;
    }
  }

  &__title {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    color: $white;
    text-transform: uppercase;
  }

  &__description {
    display: flex;
    column-gap: 20px;
    
    span {
      font-size: 20px;
      font-weight: 200;
      line-height: 1.5;
      color: $white;
    }
  }

  &__btn {
    height: 59px;
    padding-right: 100px;
    width: max-content;
  }
}

@media (max-width: 1260px) {
  .block-cta {
    &__content {
      top: 30px;
      left: 0;
      row-gap: 15px;
      padding: 25px 30px;
    }

    &__title {
      font-size: 40px;
    }
  }
}

@media (max-width: 800px) {
  .block-cta {
    margin-bottom: 50px;

    &__container {
      display: flex;
      flex-direction: column;
    }

    &__content {
      position: relative;
      top: 0;
      left: 0;
      max-width: 100%;
    }
  }
}

@media (max-width: 400px) {
  .block-cta {
    &__title {
      font-size: 20px;
    }

    &__description {
      span {
        font-size: 10px;
      }
    }
  }
}
</style>