export default {
  CARS: [
    // RX
    // {
    //   model: "rx",
    //   complectation: "300",
    //   month_payment: 42200,
    //   //price: 4018000,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "rx",
    //   complectation: "350 AWD",
    //   amount: 13,
    //   month_payment: 51600,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "rx",
    //   complectation: "Black Vision",
    //   month_payment: 49800,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "rx",
    //   complectation: "350L",
    //   month_payment: 53000,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "rx",
    //   complectation: "450h AWD",
    //   month_payment: 69800,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // NX
    // {
    //   model: "nx",
    //   complectation: "200",
    //   month_payment: 16300,
    //   //price: 2830000,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "nx",
    //   complectation: "200 AWD",
    //   month_payment: 19000,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "nx",
    //   complectation: "Black Vision",
    //   month_payment: 20500,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "nx",
    //   complectation: "300 AWD",
    //   month_payment: 22300,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "nx",
    //   complectation: "300H AWD",
    //   month_payment: 25900,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "new_nx",
    //   complectation: "250",
    //   month_payment: false,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // LX
    
    {
      model: "lx600",
      name: "LX 600",
      complectation: "600",
      month_payment: 52400,
      advantage: "2 500 000",
      key_to_key: true,
      buyback: true,
      extra_trade_in: true,
      profitable_credit: true,
      casco: false,
    },
    {
      model: "lx500d",
      name: "LX 500 D",
      complectation: "500D",
      month_payment: 52400,
      advantage: "2 500 000",
      key_to_key: true,
      buyback: true,
      extra_trade_in: true,
      profitable_credit: true,
      casco: false,
    },
    {
      model: "rx",
      complectation: "300 AWD",
      month_payment: 43000,
      advantage: "1 800 000",
      profitable_credit: true,
      key_to_key: true,
      buyback: true,
      extra_trade_in: true,
      new_image: true,
    },
    {
      model: "nx",
      complectation: "350",
      month_payment: false,
      advantage: "1 000 000",
      profitable_credit: true,
      key_to_key: true,
      buyback: true,
      extra_trade_in: true,
      new_image: true,
    },
    
    // {
    //   model: "rx_new",
    //   name: "RX",
    //   new: false,
    //   complectation: "300 AWD",
    //   month_payment: 43000,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    //   new_image: true,
    //   // advantage: "1 400 000",
    //   advantages: [
    //     "Легкий обмен<br /><b>«Ключ в ключ»</b>",
    //     "Спец. цена при <br /><b>трейд-ин</b>",
    //     "<b>2 года</b> <br />гарантии",
    //     "Постановка на учет <br />в ГИБДД <b>в подарок</b>",
    //     "<b>ТРЕЙД-ИН</b> <br /> ЗА 2 ЧАСА",
    //   ],
    //   images: 10,
    //   img_format: ".png",
    // },
    {
      model: "gx",
      complectation: "460",
      month_payment: 59300,
      // advantage: "1 300 000",
      key_to_key: true,
      buyback: false,
      profitable_credit: true,
      extra_trade_in: true,
      buy_in_stock: true,
      new_image: true,
    },
    {
      model: "es",
      complectation: "200",
      month_payment: 34000,
      // advantage: "1 300 000",
      profitable_credit: true,
      key_to_key: true,
      buyback: true,
      extra_trade_in: true,
      new_image: true,
    },
    {
      model: "es",
      complectation: "250",
      month_payment: 34000,
      profitable_credit: true,
      key_to_key: true,
      buyback: true,
      extra_trade_in: true,
    },
    {
      model: "es",
      complectation: "350",
      month_payment: 34000,
      profitable_credit: true,
      key_to_key: true,
      buyback: true,
      extra_trade_in: true,
    },
    {
      model: "tx",
      complectation: "200",
      month_payment: 34000,
      // advantage: "1 300 000",
      profitable_credit: true,
      key_to_key: true,
      buyback: true,
      extra_trade_in: true,
      new_image: true,
    },
    {
      model: "rx_v",
      name: "RX V",
      new: false,
      complectation: "300 AWD",
      month_payment: 43000,
      profitable_credit: true,
      key_to_key: true,
      buyback: true,
      extra_trade_in: true,
      new_image: true,
    },

    // {
    //   model: "rx new",
    //   complectation: "300 AWD",
    //   month_payment: 43000,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    //   new_image: true,
    // },
    
    // {
    //   model: "lx 500",
    //   complectation: "500",
    //   month_payment: 52400,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    //   profitable_credit: true,
    //   casco: false,
    // },
    // {
    //   model: "lx 600",
    //   complectation: "600",
    //   month_payment: 52400,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    //   profitable_credit: true,
    //   casco: false,
    //   new_image: true,
    // },
    // {
    //   model: "lx",
    //   complectation: "570",
    //   month_payment: 78900,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "lx",
    //   complectation: "HERITAGE",
    //   month_payment: 116200,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "lx",
    //   complectation: "Black Vision",
    //   month_payment: 134800,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // Новый LX
    // {
    //   model: "new_lx",
    //   complectation: "500d",
    //   month_payment: false,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "new_lx",
    //   complectation: "600",
    //   month_payment: false,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // ES
    
    
    // {
    //   model: "lc",
    //   complectation: "300",
    //   month_payment: false,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },

    // LS
    // {
    //   model: "ls",
    //   complectation: "350",
    //   month_payment: 45500,
    //   key_to_key: true,
    //   profitable_credit: true,
    //   buyback: false,
    //   extra_trade_in: true,
    //   buy_in_stock: true,
    // },
    // {
    //   model: "ls",
    //   complectation: "500",
    //   month_payment: 55400,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // GX
    // {
    //   model: "gx",
    //   complectation: "460 Executive",
    //   month_payment: 59300,
    //   key_to_key: true,
    //   buyback: false,
    //   profitable_credit: true,
    //   extra_trade_in: true,
    //   buy_in_stock: true,
    // },
    // {
    //   model: "gx",
    //   complectation: "460 Executive Sport",
    //   month_payment: 71400,
    //   key_to_key: true,
    //   buyback: false,
    //   profitable_credit: true,
    //   extra_trade_in: true,
    //   buy_in_stock: true,
    // },
    // {
    //   model: "gx",
    //   complectation: "460 Premium",
    //   month_payment: 76000,
    //   key_to_key: true,
    //   buyback: false,
    //   profitable_credit: true,
    //   extra_trade_in: true,
    //   buy_in_stock: true,
    // },
    // {
    //   model: "gx",
    //   complectation: "460 Premium Sport",
    //   month_payment: 86700,
    //   key_to_key: true,
    //   buyback: false,
    //   profitable_credit: true,
    //   extra_trade_in: true,
    //   buy_in_stock: true,
    // },

    // UX
    // {
    //   model: "ux",
    //   complectation: "200",
    //   month_payment: 25900,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // {
    //   model: "ux",
    //   complectation: "250H AWD",
    //   month_payment: 31400,
    //   //price: 2470000,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: true,
    //   extra_trade_in: true,
    // },
    // // LC
    // {
    //   model: "lc",
    //   complectation: "500",
    //   month_payment: 59600,
    //   credit_rate: false,
    //   profitable_credit: true,
    //   key_to_key: true,
    //   buyback: false,
    //   extra_trade_in: true,
    //   buy_in_stock: true,
    // },
  ],
};
