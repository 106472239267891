<template>
  <article class="car" :class="[device_platform]" :id="car.model">
    <div class="car__body">
      <div class="car__content">
        <h3 class="car__title">
          Lexus {{ car.name }}<span v-if="car.new">_NEW</span>
        </h3>
        <p v-if="car.advantage" class="car__subtitle">
          ВЫГОДА ДО <b>{{ car.advantage }} ₽</b>
        </p>
        <button
          class="car__button button button_theme_primary"
          @click="handleClick"
        >
          Получить предложение
        </button>

        <div
          class="car__nav-swiper swiper-container"
          :class="`car__nav-swiper_${car.model}`"
        >
          <div class="swiper-wrapper">
            <div
              class="car__nav-slide swiper-slide"
              v-for="img in car.images"
              :key="img"
              @click="changeSlide(img - 1)"
            >
              <img
                class="car__nav-img"
                :class="{ active: img - 1 === currentSlide }"
                :src="
                  require(`../images/cars/new/${car.model}/${img}${car.img_format}`)
                "
                :alt="`Фото ${car.model}`"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="car__image">
        <div
          class="car__swiper swiper-container"
          :class="`car__swiper_${car.model}`"
        >
          <div class="car__swiper-wrapper swiper-wrapper">
            <div
              class="car__swiper-slide swiper-slide"
              v-for="img in car.images"
              :key="img"
            >
              <img
                class="car__swiper-slide-img"
                :src="
                  require(`../images/cars/new/${car.model}/${img}${car.img_format}`)
                "
                :alt="`Фото ${car.model}`"
              />
            </div>
          </div>

          <div
            class="car__swiper-arrow-prev swiper-button-prev"
            :class="`car__swiper-arrow-prev_${car.model}`"
          ></div>
          <div
            class="car__swiper-arrow-next swiper-button-next"
            :class="`car__swiper-arrow-next_${car.model}`"
          ></div>
        </div>
      </div>

      <h3 class="car__title car__title--mobile">
        Lexus {{ car.name }}<span v-if="car.new">_NEW</span>
      </h3>
    </div>
    <ul class="car__advantages">
      <li
        v-for="item in car.advantages"
        v-html="item"
        class="car__advantage"
        @click="handleClick"
      ></li>
    </ul>
  </article>
</template>

<script>
import finance from "../common/finance";
import Mixin from "../common/mixin";
import Observer from "./observer";
import Swiper from "swiper";

export default {
  mixins: [finance, Mixin],
  props: ["car"],
  components: { Observer, Swiper },
  data() {
    return {
      swiper: null,
      navSwiper: null,
      currentSlide: 0,
    };
  },
  mounted() {
    this.initSwiper(this.car);
    this.initNavSwiper(this.car);
  },
  methods: {
    initSwiper(car) {
      this.swiper = new Swiper(`.car__swiper_${car.model}`, {
        navigation: {
          nextEl: `.car__swiper-arrow-next_${car.model}`,
          prevEl: `.car__swiper-arrow-prev_${car.model}`,
        },
      });

      this.swiper.on("slideChange", (e) => {
        if (this.navSwiper) {
          this.currentSlide = this.swiper.activeIndex;
        }
      });
    },
    initNavSwiper(car) {
      this.navSwiper = new Swiper(`.car__nav-swiper_${car.model}`, {
        slidesPerView: 3,
        spaceBetween: 20,
      });
    },
    changeSlide(index) {
      if (this.swiper) {
        this.swiper.slideTo(index);
      }
    },
    handleClick() {
      this.getCall({ type: "sale", form: "cars" });
    },
    getCarImage(car) {
      return require(`../images/cars/new/${car.model.toLowerCase()}.jpg`);
    },
  },
};
</script>

<style lang="scss">
img {
  max-width: 100%;
}

.car {
  width: 100%;
  background: #fff;
  padding: 50px;
  position: relative;
  margin-bottom: 30px;

  &__body {
    display: flex;
  }

  &__content {
    width: 46%;
    margin-right: 80px;
  }

  &__button {
    margin-bottom: 100px;
    width: 100%;
    height: 59px;
  }

  &__title {
    text-transform: uppercase;
    font-size: 74.348px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 7px 0;
    border-top: 2px solid black;
    border-bottom: 2px solid black;

    span {
      color: #ec1c24;
    }

    &--mobile {
      display: none;
    }
  }

  &__subtitle {
    color: #d83831;
    font-size: 47.661px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 25px 0;
  }

  &__image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  &__credit {
    text-transform: uppercase;
  }

  &__credit-title {
    display: block;
    font-size: 16px;
  }

  &__credit-value {
    font-size: 25px;
  }

  &__advantages {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    cursor: pointer;
    padding-top: 65px;
  }

  &__advantage {
    width: 20%;
    text-align: center;
    text-transform: uppercase;

    &:not(:last-child) {
      border-right: 1px solid #000;
    }

    b {
      font-family: "Favorit Bold", sans-serif;
    }
  }

  &__image {
    position: relative;
  }

  &__swiper {
    width: 100%;
    max-width: 100%;
  }

  &__swiper-slide {
    max-width: 100%;
  }

  &__nav-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__nav-img {
    max-height: 88px;
    transform: scale(0.7);
    transition: transform 0.2s;

    &.active {
      transform: scale(1);
    }
  }
}

.car {
  &__swiper {
    width: 600px;
  }
  //
  //&__colors {
  //  position: absolute;
  //  bottom: 0;
  //  display: flex;
  //  justify-content: center;
  //
  //  &-item {
  //    width: 20px;
  //    height: 20px;
  //    border-radius: 50%;
  //  }
  //}
}

@media (max-width: 1440px) {
  .car {
    &__content {
      width: 40%;
    }

    &__title {
      font-size: 40px;
    }

    &__subtitle {
      font-size: 30px;
    }
  }
}

@media (max-width: 1280px) {
  .car {
    padding: 20px 0;

    &__body {
      flex-direction: column-reverse;
    }

    &__content {
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    &__nav-swiper {
      order: 3;
    }

    &__button {
      max-width: 350px;
      margin: 0;
      order: 1;
    }

    &__subtitle {
      order: 2;
    }

    &__title {
      display: none;

      &--mobile {
        display: block;
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 850px) {
  .car {
    &__advantages {
      flex-direction: column;
      gap: 10px;
      padding-top: 30px;
    }

    &__advantage {
      width: 100%;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      padding: 5px 0;

      &:not(:last-child) {
        border-right: none;
      }
    }
  }
}

@media (max-width: 640px) {
  .car {
    &__title {
      font-size: 35px;
    }

    &__subtitle {
      font-size: 20px;
    }

    &__swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__swiper-slide-img {
      max-height: 182px;
    }

    &__nav-swiper {
      margin-top: 30px;
    }
  }
}
</style>
