import { FORMAT_OPTIONS } from './constants';

/**
 * @typedef {('number' | 'shortNumber' | 'string' | 'shortString')} FormatType
 */

/**
 * Возвращает дату в нужном виде
 * @param {string} date - 'YYYY-MM-DD'
 * @param {Format} [format] - 'number' | 'string'
 * @returns {string}
 * @example
 * formatDate('2000-01-25') // '25.01.2000'
 * formatDate('2000-01-25', 'string') // '25 декабря 2000 г.'
 */
export function formatDate(date, format = 'number') {
  if (!date) {
    throw new Error('Необходимо передать дату для форматирования');
  }

  if (format === 'default') {
    return date;
  }

  return date.toLocaleDateString('ru-RU', FORMAT_OPTIONS[format]);
}

/**
 * Возвращает стандартизированный формат даты (date-time form), для кроссбраузерности
 * @param {string} date - 'YYYY-MM-DD'
 * @returns {string}
 * @example
 * standartDate('2000-01-25') // '2000-01-25T00:00:00'
 * standartDate('2000-01-25T15:00:00') // 2000-01-25T15:00:00
 * standartDate('2000-01-25 15:00:00') // '2000-01-25T15:00:00'
 */
export function standartDate(date) {
  if (date.length === 10) {
    return new Date(`${date}T00:00:00`);
  } else if (date.includes('T')) {
    return new Date(date);
  } else {
    return new Date(date.replace(' ', 'T'));
  }
}
