<template>
	<div class="block-agreement agreement modal">
		<div class="blacking" @click="hideSelf"></div>
		<div class="agreement__container">
			<img
				src="../images/utils/close.svg"
				alt="Закрыть"
				class="thanks__close"
				@click="hideSelf"
			/>
			<div class="agreement__content">
				<h3 class="agreement__title">
					СОГЛАСИЕ<br />
					на обработку персональных данных и получение рекламы<br />
					(далее – «Согласие»)
				</h3>
				<div class="agreement__paragraph">
					Настоящим я, во исполнение требований Федерального закона от
					27.07.2006 г. № 152-ФЗ «О персональных данных» (с изменениями и
					дополнениями), Федерального закона от 13.03.2006 N 38-ФЗ «О рекламе» с
					изменениями и дополнениями) свободно, своей волей и в своем интересе
					даю свое согласие: Обществу с ограниченной ответственностью «РОЛЬФ»,
					адрес: 141410, Московская обл.,г. Химки, Ленинградское шоссе, вл. 21
					(далее – «Общество»), его филиалам и обособленным подразделениям, а
					также следующим Операторам (далее – «Операторы»):

					<li>
						ООО «РОЛЬФ МОТОРС», адрес: 142770, г. Москва, поселение Сосенское,
						поселок Газопровод, 39-ый км МКАД (внешняя сторона), владение 7;
					</li>
					<li>
						ООО «РОЛЬФ Эстейт Санкт-Петербург», адрес: 196105, Санкт-Петербург,
						Витебский пр., д. 17, корп. 6, лит Б, О, его филиалам и обособленным
						подразделениям;.
					</li>
					<li>
						ООО "Атос АйТи Солюшенс энд Сервисез", адрес: 115432, город Москва,
						проспект Андропова, дом 18, корпус 5
					</li>
				</div>
				<div class="agreement__paragraph">
					на обработку своих персональных данных, указанных при регистрации
					и/или оставлении заявки на получение предложения и/или подписании на
					получение рекламной информации путем заполнения веб-формы на сайте
					Общества (www.rolf.ru) и его поддоменов *.rolf.ru (далее –«Сайт»),
					направляемой (заполненной) с использованием Сайта. Под персональными
					данными понимается любая информация, относящаяся ко мне как к субъекту
					персональных данных, в том числе: фамилия, имя, отчество, паспортные
					данные, дата и место рождения, адрес места жительства, контактные
					данные (номер домашнего, мобильного, рабочего телефонов, адрес
					электронной почты), семейное, социальное, имущественное положение,
					образование, профессия, информация об автомобиле (в случае наличия
					автомобиля), данные обо мне, которые станут известны в ходе исполнения
					договоров (в случае заключения договоров между мной и Обществом), а
					также иная общедоступная информация обо мне.
				</div>
				<div class="agreement__paragraph">
					2. Я согласен с тем, что в рамках обработки персональных данных
					Общество, а также Операторы, поименованные выше, вправе осуществлять
					сбор, запись, систематизацию, накопление, анализ, использование,
					извлечение, распространение, передачу Операторам и / или любым иным
					третьим лицам (включая, но не ограничиваясь: страховым организациям;
					организациям владельцам-серверов; банковским и иным кредитным
					организациям; организациям, оказывающим услуги по осуществлению
					звонков, смс - рассылок, любых иных видов рассылок и уведомлений;
					организациям, оказывающим услуги по проведению различных опросов и
					исследований и пр.), получение, обработку, хранение, уточнение
					(обновление, изменение), обезличивание, блокирование, удаление,
					уничтожение моих персональных данных путем ведения баз данных
					автоматизированным, механическим, ручным способами в целях:
					<li>ведения и актуализации клиентской базы;</li>
					<li>
						получения и исследования статистических данных об объемах продаж и
						качестве оказываемых услуг;
					</li>
					<li>проведения маркетинговых программ;</li>
					<li>
						изучения конъюнктуры рынка автомобилей, автомобильных запасных
						частей и аксессуаров, услуг по ремонту автомобилей;
					</li>
					<li>
						проведению опросов и исследований, направленных на выявление
						удовлетворенности/неудовлетворенности Пользователя, постоянного
						совершенствования уровня предоставляемых услуг;
					</li>
					<li>
						информирования меня о предлагаемых Обществом/Оператором автомобилях,
						запасных частях и аксессуарах, оказываемых услугах, проводимых
						бонусных мероприятий, акций и т.д.;
					</li>
					<li>
						рекламирования и иного любого продвижения товаров и услуг на рынке
						путем осуществления прямых контактов со мной и иными потребителями;
					</li>
					<li>
						реализации страховых продуктов, в том числе, но не ограничиваясь,
						оформление полисов КАСКО, ОСАГО и т.д.;
					</li>
					<li>
						технической поддержки при обработке информации, документации и
						персональных данных с использованием средств автоматизации и без
						такого использования.
					</li>
				</div>
				<div class="agreement__paragraph">
					3. Я выражаю согласие на получение рекламы и разрешаю Обществу и
					Операторам осуществлять в мой адрес смс-рассылки, а также иные виды
					рассылок и уведомлений, в том числе рекламного характера, с
					использованием любых средств связи.
				</div>
				<div class="agreement__paragraph">
					4. Я выражаю согласие и разрешаю Обществу и Операторам объединять
					персональные данные в информационную систему персональных данных и
					обрабатывать мои персональные данные, с помощью средств автоматизации
					либо без использования средств автоматизации, а также с помощью иных
					программных средств, специально разработанных по поручению Общества и
					Операторов. Работа с информационными системами персональных данных
					осуществляется по предписанному Обществом и/или Операторами алгоритму
					(сбор, систематизация, накопление, хранение, уточнение, использование,
					блокирование, уничтожение, др.). Используемые способы обработки,
					включая, но не ограничиваясь: автоматическая сверка почтовых кодов с
					базой кодов, автоматическая проверка написания названий улиц,
					населенных пунктов, автоматическая проверка действительности VIN,
					уточнение данных со мной (посредством почтовой связи, электронной
					почты, телефонной связи (включая мобильную связь), факсимильной связи,
					сети Интернет), сегментация информационной базы по заданным критериям,
					периодические контакты со мной посредством телефонной связи (включая
					мобильную связь), посредством электронной почты или сети Интернет.
				</div>
				<div class="agreement__paragraph">
					5. Настоящим я уведомлен Обществом, а также от имени Операторов о том,
					что предполагаемыми пользователями персональных данных являются
					работники Общества/Операторов (а также лица, привлеченные на условиях
					гражданско-правового договора).
				</div>
				<div class="agreement__paragraph">
					6. Я ознакомлен(а), что :

					<li>
						настоящее согласие на обработку моих персональных данных и получение
						рекламы является бессрочным и может быть отозвано посредством
						направления в адрес Общества и Операторов, указанных в пункте 1
						настоящего Согласия, письменного заявления. Датой отзыва считается
						день, следующий за днём вручения Обществу и Операторам письменного
						заявления об отзыве согласия на обработку персональных данных и/или
						получение рекламы;
					</li>
					<li>
						имею право на доступ к своим персональным данным, требовать
						уточнения (обновление, изменение) моих персональных данных, а также
						удаления и уничтожения моих персональных данных в случае их
						обработки Обществом и вышеуказанными Операторами, нарушающих мои
						законные права и интересы, законодательство Российской Федерации.
					</li>
				</div>
				<div class="agreement__paragraph">
					7. Я обязуюсь в случае изменения моих персональных данных, сведений
					обо мне незамедлительно сообщать Обществу и/или Оператору, с
					предоставлением подтверждающих документов.
				</div>
				<div class="agreement__paragraph">
					8. Настоящее Согласие признается мной, Обществом, Операторами моим
					письменным согласием на обработку моих персональных данных и получение
					рекламы, данным согласно ст. 9 Федерального закона от 27.07.2006 г.
					№152-ФЗ «О персональных данных» и ст.18 Федерального закона от
					13.03.2006 г. №38-ФЗ «О рекламе».
				</div>
				<div class="agreement__paragraph">
					9. Настоящим Согласием я подтверждаю, что являюсь субъектом
					предоставляемых персональных данных, а также подтверждаю достоверность
					предоставляемых данных.
				</div>
				<div class="agreement__paragraph">
					10. Я осознаю, что регистрируясь и/или оставляя заявку на получение
					предложения и/или подписываясь на получение рекламной информации на
					Сайте, означает мое письменное согласие с условиями, описанными в
					настоящем Согласии.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "full-agreement",
	props: ["type"],
	methods: {
		hideSelf() {
			this.$emit("close");
		},
	},
};
</script>

<style scoped lang="scss">
.agreement {
	&__container {
		top: 30px;
		height: calc(100vh - 90px);
		left: 15px;
		width: calc(100vw - 30px);
		position: fixed;
		z-index: 1200;
		background: #fff;
		padding: 40px 20px;
		cursor: default;
	}

	&__content {
		font-size: 14px;
		overflow: auto;
		max-height: 100%;
	}

	&__title {
		font-size: 16px;
		font-weight: 600;
		text-align: center;
		margin-bottom: 20px;
	}

	&__paragraph {
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__close {
		position: absolute;
		right: 20px;
		top: 20px;
		width: 15px;
		height: 15px;
		cursor: pointer;
	}
}
</style>
