<template>
	<div id="app">
		<block-header v-on="block_handlers" />
		<block-main v-on="block_handlers" />
		<main-row />
		<block-offer v-on="block_handlers" />
		<block-cars id="cars" :amounts="amounts" :choosed="choosedCars" @close="closeThings" v-on="block_handlers" />
		<block-stock v-on="block_handlers" id="stock" @amounts="setAmounts" />
		<block-cta v-on="block_handlers" />
		<block-video v-on="block_handlers" />
		<block-trade-in v-on="block_handlers" />
		<block-credit v-on="block_handlers" />
		<block-contacts @close="closeThings" id="contacts" v-on="block_handlers" />
		<block-legal v-on="block_handlers" />
		<transition name="fade">
			<full-get
				:data="data"
				@close="closeThings"
				v-on="block_handlers"
				v-show="show.get"
			/>
		</transition>
		<transition name="fade">
			<full-thanks @close="closeThings" v-if="show.thanks" />
		</transition>
		<transition name="fade">
			<full-agreement @close="closeAgreement" v-show="show.agreement" />
		</transition>
		<preloader v-if="show.load"></preloader>
	</div>
</template>

<script>
import BlockHeader from "./components/block-header";
import BlockMain from "./components/block-main";
import MainRow from "./components/main-row";
import BlockCars from "./components/block-cars";
import BlockVideo from "./components/block-video";
import BlockOffer from "./components/block-offer";
import BlockCredit from "./components/block-credit";
import BlockStock from "./components/block-stock";
import BlockCta from "./components/block-cta.vue";
import BlockTradeIn from "./components/block-trade-in";
import BlockContacts from "./components/block-contacts";
import BlockLegal from "./components/block-legal";
import FullGet from "./components/full-get";
import FullThanks from "./components/full-thanks";
import FullAgreement from "./components/full-agreement";
import Preloader from "./components/preloader";

import Mixin from "./common/mixin";
import $ from "jquery";
import smoothScrollTo from "./common/smoothScrollTo";
import Send from "./common/send";

export default {
	name: "app",
	components: {
		BlockHeader,
		BlockMain,
		BlockCars,
		BlockVideo,
		BlockOffer,
		BlockCredit,
		BlockStock,
		MainRow,
    BlockCta,
		BlockTradeIn,
		BlockContacts,
		BlockLegal,
		FullAgreement,
		FullGet,
		FullThanks,
		Preloader,
	},
	mixins: [Mixin],
	data: function () {
		return {
			show: {
				agreement: false,
				get: false,
				thanks: false,
				coupon: false,
				load: false,
			},
			data: {},
			choosedCars: null,
			dev_mode: process.env.NODE_ENV === "development",
			amounts: {}
		};
	},
	mounted() {
		$("html").on("keyup", (event) => {
			if (event.keyCode === 27) {
				this.closeThings();
			}
		});

		history.scrollRestoration = "manual";
		let hash = window.location.hash.replace("#", "");
		if (hash) {
			this.scrollTo(hash);
		}
	},
	computed: {
		block_handlers() {
			return {
				getAgreement: this.getAgreement,
				scrollTo: this.scrollTo,
				callBack: this.callThere,
				getCall: this.handleGetCall,
				getCoupon: this.handleGetCoupon,
			};
		},
	},
	methods: {
		setAmounts(cars) {
			this.amounts = cars;
		},
		closeThings() {
			this.show.get = false;
			this.show.thanks = false;
			this.show.agreement = false;
			this.show.coupon = false;
			this.data = {};
			this.unblockScroll();
		},
		closeAgreement() {
			this.show.agreement = false;
			if (!this.show.get) {
				this.unblockScroll();
			}
		},
		getAgreement() {
			this.show.agreement = true;
			this.blockScroll();
		},
		handleGetCoupon(data) {
			this.show.coupon = true;
			this.data = data;
			this.blockScroll();
		},
		handleGetCall(data) {
			this.show.get = true;
			this.data = data;
			this.blockScroll();
			if(this.data.form != 'cta') {
				ym(8467762,'reachGoal','open_form');
			}
		},
		scrollTo(where) {
			let offset = this.device_platform === "desktop" ? 110 : 160;
			smoothScrollTo($("#" + where).offset().top - offset + 10);
		},
		callThere(event) {
			let cKeeper = this.CONSTANTS.cKeeper;
			let manager_phone = this.CONSTANTS.need_manager_phone
					? $("#phone")
							.text()
							.replace(/\s/g, "\u00A0")
							.replace(/-/g, "\u2011")
							.replace(/\D+/g, "")
					: "";
			let req = Send(event, this.data, cKeeper, manager_phone);
			this.show.load = true;

			if (cKeeper === "actioncall") {
				const finishCall = () => {
					this.closeThings();
					this.show.load = false;
					this.show.thanks = true;
					if (typeof window.dataLayer !== "undefined") {
						window.dataLayer.push({
							event: "callkeeper-call_order-ckaction",
							eventCategory: "callkeeper",
							eventAction: "call_order",
							eventLabel: "ckaction",
						});
					}
					console.log("done");
				};

				if (!req) {
					finishCall();
				} else {
					req.finally(finishCall);
				}
			} else {
				this.closeThings();
				this.show.load = false;
				this.show.thanks = true;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#app {
	display: flex;
	flex-direction: column;
	align-items: center;
}
</style>
