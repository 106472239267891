const white = { color: 'white', hex: '#fff' }
const lightGrey = { color: 'lightGrey', hex: '#999' }
const grey = { color: 'grey', hex: '#444' }
const black = { color: 'black', hex: '#000' }
const blue = { color: 'blue', hex: '#1a1acc' }
const green = { color: 'green', hex: '#486664' }
const medniy = { color: 'medniy', hex: '#c94619' }

export default {
    nx: [
        { ...white, imgs: 8 },
        { ...lightGrey, imgs: 4 },
        { ...black, imgs: 8 },
    ],
    es: [
        { ...white, imgs: 4 },
        { ...black, imgs: 8 },
    ],
    gx: [
        { ...white, imgs: 15 },
        { ...black, imgs: 4 },
    ],
    lx500d: [
        { ...black, imgs: 11 },
    ],
    lx600: [
        { ...white, imgs: 4 },
        { ...black, imgs: 9 },
    ],
    rx: [
        { ...white, imgs: 8 },
        { ...lightGrey, imgs: 13 },
        { ...grey, imgs: 8 },
        { ...blue, imgs: 9 },
        { ...black, imgs: 8 },
    ],
    rx_new: [
        { ...white, imgs: 8 },
        { ...green, imgs: 8 },
        { ...medniy, imgs: 10 },
        { ...lightGrey, imgs: 8 },
        { ...grey, imgs: 8 },
        { ...blue, imgs: 9 },
        { ...black, imgs: 18 },
    ],
    tx: [
        { ...black, imgs: 9 },
    ],
    rx_v: [
        { ...white, imgs: 8 },
        { ...green, imgs: 8 },
        { ...medniy, imgs: 10 },
        { ...lightGrey, imgs: 8 },
        { ...grey, imgs: 8 },
        { ...blue, imgs: 9 },
        { ...black, imgs: 18 },
    ]
}