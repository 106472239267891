<template>
  <section class="block-credit credit">
    <div class="credit__container container">
      <div class="credit__content">
        <h2 class="credit__title">
          КРЕДИТ
        </h2>
        <p class="credit__subtitle">
          Привлекательные условия кредитования
          <br>на новый Lexus
          <!-- со ставкой <br><b>от 3,9% годовых.</b> -->
        </p>
        <button class="credit__button button button_theme_primary"
          @click="getCall({type: 'sale', form: 'credit', title: 'Оставить заявку'})"
        >
          Оставить заявку
          <!-- <img src="@/images/temporary/lights.png" alt="" class="credit__lights"> -->
        </button>
      </div>
    </div>

    <div class="credit__image" />

    <!--<img src="@/images/temporary/gift2.png" class="credit__temporary">-->
  </section>
</template>

<script>
import Mixin from "../common/mixin";

export default {
	name: "block-credit",
	mixins: [Mixin],
}
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.credit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
	height: 622px;
	z-index: 5;
  //background: url("../images/background/bg-credit.jpg") no-repeat;
  //background-position: top left;

  &:after{
    position: absolute;
    content:'';
    left: 0;
    width: 50%;
    height: 100%;
    background: url("../images/background/bg-credit.jpg") no-repeat center/cover;
  }

  &__container {
    display: flex;
    justify-content: flex-end;
  }
  &__content {
    width: 530px;
    padding: 65px 0 65px 70px;
    background: white;
  }
  &__title {
    font-family: "Favorit Bold", sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
    text-transform: uppercase;
    padding: 5px;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    margin-bottom: 25px;
  }
  &__subtitle {
    font-family: "Favorit Light", sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 25px;
  }
  &__button {
		height: 50px;
    width: 100%;
  }
  &__lights {
    position: absolute;
    top: 100%;
    left: 0;
  }
  /*&__temporary {
    position: absolute;
    right: 0px;
    bottom: -95px;
  }*/
}

@media (max-width: 1500px) {
  .credit {
    &__content {
      width: 430px;
    }
  }
}

@media (max-width: 1320px) {
  .credit {
    background-position-x: -120px;
  }
}

@media (max-width: 1020px) {
  .credit {
    display: flex;
    flex-direction: column-reverse;
    background: none;
    height: auto;

    &:after{
      display: none;
    }

    &__content {
      width: 100%;
      padding: 75px 40px;
    }
    &__image {
      display: block;
      width: 100%;
      height: 350px;
      background: url("../images/background/bg-credit.jpg") no-repeat right;
      background-size: cover;
    }
    /*&__temporary {
      display: none;
    }*/
  }
}

@media (max-width: 470px) {
  .credit__image {
    background-size: 130%;
  }
}
</style>
