<template>
	<section class="block-contacts contacts">
		<observer @display="waitYmaps"></observer>
		<div class="contacts__container container">
			<div class="contacts__content">
				<h2 class="contacts__title">Контакты</h2>
				<p class="contacts__subtitle">LEXUS РОЛЬФ Волгоградский</p>
				<div class="contacts__wrapper">
					<div>
						<p class="contacts__address">
							{{ CONSTANTS.offices[0].address }}
						</p>
						<p class="contacts__worktime">
							Ежедневно с {{ CONSTANTS.worktime }}
						</p>
					</div>

				</div>
				
				<span class="call_phone_4">
					<a class="contacts__phone bot_phone" @click="sendMetricGoal" :href="`tel:${CONSTANTS.phone_raw}`">
						{{ CONSTANTS.phone }}
					</a>
				</span>

				<button class="contacts__button button button_theme_secondary"
					@click="getCall({type: 'sale', form:'contacts', title: 'Заказать звонок'})"
				>Заказать звонок
				</button>
			</div>
		</div>

		<div class="contacts__map" id="ya-karto"></div>
	</section>
</template>

<script>
import Mask from "../common/mask";
import Mixin from "../common/mixin";
import Keyup from "../common/keyup";
import Observer from "./observer.vue";
import placemarkImg from "../images/utils/map-marker.png";
import CONSTANTS from "../common/constants";

export default {
	name: "block-contacts",
	components: { Observer },
	directives: { Mask, Keyup },
	mixins: [Mixin],
	data() {
		return {
			phone: "",
			CONSTANTS
		};
	},
	mounted() {
		// this.$nextTick(this.waitYmaps);
	},
	methods: {
		initYandexMap() {
			const isMobile = {
				Android: function () {
					return navigator.userAgent.match(/Android/i);
				},
				BlackBerry: function () {
					return navigator.userAgent.match(/BlackBerry/i);
				},
				iOS: function () {
					return navigator.userAgent.match(/iPhone|iPad|iPod/i);
				},
				Opera: function () {
					return navigator.userAgent.match(/Opera Mini/i);
				},
				Windows: function () {
					return navigator.userAgent.match(/IEMobile/i);
				},
				any: function () {
					return (
						isMobile.Android() ||
						isMobile.BlackBerry() ||
						isMobile.iOS() ||
						isMobile.Opera() ||
						isMobile.Windows()
					);
				},
			};

			let zoomControl = new ymaps.control.ZoomControl({
				options: {
					position: {
						left: "auto",
						right: 9,
						top: 108,
					},
				},
			});

			let center_x = this.CONSTANTS.coords.x_center;
			let center_y = this.CONSTANTS.coords.y_center;

			let myMap = new ymaps.Map(
				"ya-karto",
				{
					center: [center_y, center_x],
					zoom: 16,
					controls: [],
				},
				{
					searchControlProvider: "yandex#search",
				}
			);
			if (!isMobile.any()) {
				myMap.controls.add(zoomControl);
			}

			this.CONSTANTS.offices.forEach((point, i) => {
				myMap.geoObjects.add(
					new ymaps.Placemark(
						[point.y, point.x],
						{
							iconContent: point.address,
						},
						{
							iconLayout: "default#image",
							iconImageHref: placemarkImg,
							iconImageSize: [30, 40],
							// iconImageOffset: [-40, -24],
							iconContentOffset: [45, 3],
						}
					)
				);
			});

			if (isMobile.any() || this.device_platform !== "desktop") {
				myMap.behaviors.disable("drag");
			}
			myMap.behaviors.disable("scrollZoom");
		},
		waitYmaps() {
			let script = document.createElement("script");
			script.src = "//api-maps.yandex.ru/2.1/?load=package.standard&lang=ru_RU";
			document.head.appendChild(script);
			script.onload = () => {
				ymaps.ready(this.initYandexMap);
			};
		},
        sendMetricGoal(){
            if(ym){
                ym(8467762,'reachGoal','click_number');
            }
        },
	},
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.contacts {
	width: 100%;
	position: relative;
	z-index: 5;

	&__content {
		position: absolute;
		top: 10px;
		width: 458px;
		padding: 50px 40px 30px 40px;
		background-color: black;
		color: white;
		z-index: 2;
	}
	&__title {
		font-family: "Favorit Bold";
		font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 0px;
		color: white;
		border-top: 2px solid white;
		border-bottom: 2px solid white;
	}
	&__subtitle {
		font-family: "Favorit Bold";
		font-size: 18px;
    font-weight: 700;
		margin-bottom: 16px;
	}
	&__address {
		font-size: 16px;
		color: white;
	}
	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__worktime {
		font-size: 14px;
		color: #909090;
	}
	&__phone {
		font-family: "Favorit Bold";
		font-size: 24px;
    font-weight: 700;
		display: block;
		color: white;
		padding: 25px 0;
	}
	&__button {
		margin-top: 5px;
		width: 100%;
		height: 50px;
	}
	&__map {
		width: 100%;
		height: 400px;
	}
}

@media (max-width: 767px) {
	.contacts {
		&__container {
			padding: 0;
		}
		&__content {
			position: static;
			width: 100%;
		}
	}
}
</style>
