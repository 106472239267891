export default [

	{
		model: "RX300",
		name: "RX300AWD",
		compl: "Executive",
		engine: "2.0 бензин, 6 АТ, 4WD, 238 лс",
		price: "7 290 000 ₽",
		opt_show: false,
		options: [
			"Светодиодные фары ближнего и дальнего света;",
			'колесные диски 18";',
			"Apple CarPlay и Android Auto;",
			"Боковые зеркала заднего вида с повторителями указателей поворота, электрорегулировкой, электроприводом складывания и автоматическим затемнением;",
			"Интеллектуальная система доступа в автомобиль и запуск двигателя нажатием кнопки Smart Entry & Push Start;",
			"Датчик дождя;",
			"Датчик света;",
			"Электропривод крышки багажника;",
			"Рулевая колонка, боковые зеркала заднего вида и сиденье водителя с функцией памяти в трех положениях;",
			"Вентиляция первого ряда сидений;",
			"Подогрев первого и второго рядов сидений;",
			"Подогрев рулевого колеса.",
		],
	},
	{
		model: "RX300",
		name: "RX300AWD",
		compl: "Premium",
		engine: "2.0 бензин, 6 АТ, 4WD, 238 лс",
		price: "7 590 000 ₽",
		opt_show: false,
		opt_title: "Дополнительно к комплектации Executive:",
		options: [
			'колесные диски 20";',
			'Мультифункциональный дисплей 12.3";',
			"Система помощи при выезде с парковки задним ходом с функцией визуального, звукового оповещения, автоматического торможения и распознавания пешеходов (RCTAB);",
			'Система мониторинга "слепых зон" в боковых зеркалах (BSM)',
		],
	},
	{
		model: "RX300",
		name: "RX300AWD",
		compl: "Comfort",
		engine: "2.0 бензин, 6 АТ, 4WD, 238 лс",
		price: "7 290 000 ₽",
		opt_show: false,
		options: [],
	},
	{
		model: "RX300",
		name: "RX300AWD",
		compl: "F SPORT",
		engine: "2.0 бензин, 6 АТ, 4WD, 238 лс",
		price: "7 290 000 ₽",
		opt_show: false,
		options: [],
	},
	{
		model: "RX300",
		name: "RX300AWD",
		compl: "Luxury",
		engine: "2.0 бензин, 6 АТ, 4WD, 238 лс",
		price: "7 290 000 ₽",
		opt_show: false,
		options: [],
	},

	{
		model: 'RX',
		name: 'RX 450h',
		compl: 'Comfort',
		engine: 'Бензин 249 лс, автоматическая коробка, полный привод',
		price: '11 190 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'RX',
		name: 'RX 450h',
		compl: 'Executive',
		engine: 'Бензин 249 лс, автоматическая коробка, полный привод',
		price: '11 190 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'RX',
		name: 'RX 450h',
		compl: 'Executive Line',
		engine: 'Гибрид 250 лс, коробка-вариатор, полный привод',
		price: '10 690 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'RX',
		name: 'RX 450h',
		compl: 'Executive+',
		engine: 'Бензин 249 лс, автоматическая коробка, полный привод',
		price: '12 490 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'RX',
		name: 'RX 450h',
		compl: 'F SPORT',
		engine: 'Гибрид 250 лс, коробка-вариатор, полный привод',
		price: '11 190 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'RX',
		name: 'RX 450h',
		compl: 'Luxury',
		engine: 'Бензин 238 лс, автоматическая коробка, полный привод',
		price: '9 490 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'RX',
		name: 'RX 450h',
		compl: 'Premium',
		engine: 'Бензин 238 лс, автоматическая коробка, полный привод',
		price: '8 390 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'RX',
		name: 'RX 450h',
		compl: 'Prestige',
		engine: 'Бензин 279 лс, автоматическая коробка, полный привод',
		price: '8 640 000 ₽',
		opt_show: false,
		options: [],
	},

	{
		model: 'LX',
		name: 'LX 570',
		compl: 'Executive',
		engine: 'Дизель 299 лс, автоматическая коробка, полный привод',
		price: '19 190 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'LX',
		name: 'LX 570',
		compl: 'F Sport',
		engine: 'Дизель 299 лс, автоматическая коробка, полный привод',
		price: '19 990 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'LX',
		name: 'LX 570',
		compl: 'Luxury',
		engine: 'Бензин 415 лс, автоматическая коробка, полный привод',
		price: '23 090 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'LX',
		name: 'LX 570',
		compl: 'Luxury+',
		engine: 'Бензин 415 лс, автоматическая коробка, полный привод',
		price: '22 180 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'LX',
		name: 'LX 570',
		compl: 'VIP',
		engine: 'Бензин 415 лс, автоматическая коробка, полный привод',
		price: '23 990 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'LX',
		name: 'LX 570',
		compl: 'VIP Black',
		engine: 'Бензин 415 лс, автоматическая коробка, полный привод',
		price: '23 990 000 ₽',
		opt_show: false,
		options: [],
	},

	{
		model: 'GX',
		name: 'GX 460',
		compl: 'Premium',
		engine: 'Бензин 296 лс, автоматическая коробка, полный привод',
		price: '13 600 000 ₽',
		opt_show: false,
		options: [],
	},

	{
		model: 'ES',
		name: 'ES 350',
		compl: 'Advance',
		engine: 'Бензин 200 лс, автоматическая коробка, передний привод',
		price: '7 990 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'ES',
		name: 'ES 350',
		compl: 'Comfort',
		engine: 'Бензин 150 лс, коробка-вариатор, передний привод',
		price: '6 990 000 ₽',
		opt_show: false,
		options: [],
	},

	{
		model: 'TX',
		name: 'TX350',
		compl: 'Premium',
		engine: 'Бензин 279 лс, автоматическая коробка, полный привод',
		price: '13 490 000 ₽',
		opt_show: false,
		options: [],
	},

	{
		model: 'NX',
		name: 'NX300',
		compl: 'Executive',
		engine: 'Бензин 200 лс, автоматическая коробка, полный привод',
		price: '9 290 000 ₽',
		opt_show: false,
		options: [],
	},
	{
		model: 'NX',
		name: 'NX300',
		compl: 'F Sport Luxury',
		engine: 'Бензин 279 лс, автоматическая коробка, полный привод',
		price: '9 990 000 ₽',
		opt_show: false,
		options: [],
	},

	{
		model: 'UX',
		name: 'UX200',
		compl: '#enjoy',
		engine: 'Бензин 150 лс, коробка-вариатор, передний привод',
		price: '5 990 000 ₽',
		opt_show: false,
		options: [],
	},



	/*
        // RX350
        {
            model: "RX",
            name: "RX350",
            compl: "Comfort",
            engine: "2.4 бензин, 8 АТ, 4WD, 249 лс",
            price: "9 150 000 ₽",
            opt_show: false,
            options: [
                "мультимедиа система Lexus Link с сенсорным дисплеем; Apple CarPlay и Android auto;",
                "DRCC - (Dynamic Radar Cruise Control) - динамический радарный круиз-контроль;",
                "LDA - (Lane Departure Alert) - предупреждение о выходе из полосы движения;",
                "LTA - (Lane Tracing Assist) - ассистент отслеживания полосы движения;",
                'Легкосплавные диски 19"; дверные ручки с электроприводом E-Latch;',
                "Камера заднего вида;",
                "Электропривод крышки багажника",
            ],
        },
        {
            model: "RX",
            name: "RX350",
            compl: "Comfort",
            engine: "2.4 бензин, 8 АТ, 4WD, 279 лс",
            price: "8 850 000 ₽",
            opt_show: false,
            options: [
                "мультимедиа система Lexus Link с сенсорным дисплеем; Apple CarPlay и Android auto;",
                "DRCC - (Dynamic Radar Cruise Control) - динамический радарный круиз-контроль;",
                "LDA - (Lane Departure Alert) - предупреждение о выходе из полосы движения;",
                "LTA - (Lane Tracing Assist) - ассистент отслеживания полосы движения;",
                'Легкосплавные диски 19"; дверные ручки с электроприводом E-Latch;',
                "Камера заднего вида;",
                "Электропривод крышки багажника",
            ],
        },
        {
            model: "RX",
            name: "RX350",
            compl: "Executive",
            engine: "2.4 бензин, 8 АТ, 4WD, 249 лс",
            price: "9 900 000 ₽",
            opt_show: false,
            opt_title: "Дополнительно к комплектации Comfort:",
            options: [
                'Легкосплавные диски 21";',
                'Боковые зеркала заднего вида с подогревом, автозатемнением и функцией BSM ("слепые" зоны);',
                "Cистема автоматического переключения дальнего света на ближний (AHB);",
                "Светодиодные  фары с 3-мя линзами;",
                "Вентиляция сидений 1го ряда;",
                "HUD-Проекция на лобовое стекло с сенсорным управлением на руле) (для версии двигателем 279 лс);",
                "Панорамный стеклянный люк (для версии с двигателем 279 лс)",
            ],
        },
        {
            model: "RX",
            name: "RX350",
            compl: "Executive",
            engine: "2.4 бензин, 8 АТ, 4WD, 279 лс",
            price: "10 800 000 ₽",
            opt_show: false,
            opt_title: "Дополнительно к комплектации Comfort:",
            options: [
                'Легкосплавные диски 21";',
                'Боковые зеркала заднего вида с подогревом, автозатемнением и функцией BSM ("слепые" зоны);',
                "Cистема автоматического переключения дальнего света на ближний (AHB);",
                "Светодиодные  фары с 3-мя линзами;",
                "Вентиляция сидений 1го ряда;",
                "HUD-Проекция на лобовое стекло с сенсорным управлением на руле) (для версии двигателем 279 лс);",
                "Панорамный стеклянный люк (для версии с двигателем 279 лс)",
            ],
        },
        {
            model: "RX",
            name: "RX350",
            compl: "Luxury",
            engine: "2.4 бензин, 8 АТ, 4WD, 249 лс",
            price: "12 500 000 ₽",
            opt_show: false,
            opt_title: "Дополнительно к комплектации Executive:",
            options: [
                "Обивка сидений кожей Semi-Aniline из полуанилиновой кожи;",
                "Амбиентная подсветка салона (64 цвета);",
                "Передние сиденья с оттоманками;",
                "Рулевое колесо с кожаной отделкой и вставками из дерева, лепестками переключения и подогревом;",
                "Отделка передней панели с вставками из дерева;",
                "Камера панорамного вида (PVM);",
                "Подогрев сидений 2го ряда;",
                "Вентиляция сидений 2го ряда;",
                "Шторки задних дверей (механические);",
                "3-зонная система климат-контроля (режим S-Flow, авторециркуляция, ионизатор воздуха Nano-E);",
                "Адаптивная регулируемая подвеска (AVS)",
            ],
        },
        {
            model: "RX",
            name: "RX350",
            compl: "Luxury",
            engine: "2.4 бензин, 8 АТ, 4WD, 279 лс",
            price: "12 300 000 ₽",
            opt_show: false,
            opt_title: "Дополнительно к комплектации Executive:",
            options: [
                "Обивка сидений кожей Semi-Aniline из полуанилиновой кожи;",
                "Амбиентная подсветка салона (64 цвета);",
                "Передние сиденья с оттоманками;",
                "Рулевое колесо с кожаной отделкой и вставками из дерева, лепестками переключения и подогревом;",
                "Отделка передней панели с вставками из дерева;",
                "Камера панорамного вида (PVM);",
                "Подогрев сидений 2го ряда;",
                "Вентиляция сидений 2го ряда;",
                "Шторки задних дверей (механические);",
                "3-зонная система климат-контроля (режим S-Flow, авторециркуляция, ионизатор воздуха Nano-E);",
                "Адаптивная регулируемая подвеска (AVS)",
            ],
        },
        {
            model: "RX",
            name: "RX350",
            compl: "F SPORT",
            engine: "2.4 бензин, 8 АТ, 4WD, 249 лс",
            price: "10 900 000  ₽",
            opt_show: false,
            opt_title: "Дополнительные опции F SPORT:",
            options: [
                'Легкосплавные затемненные диски F SPORT 21";',
                "Дизайн экстерьера F SPORT: передний и задний бампер, решётка радиатора, логотип на передних крыльях;",
                "усиленная тормозная система F SPORT;",
                "Обивка салона кожей с вставками из аллюминия;",
                "Передние сиденья спортивной формы F SPORT;",
                "Рулевое колесо F SPORT с кожаной отделкой, лепестками переключения и подогревом;",
                "Цифровая панель приборов F SPORT",
            ],
        },
        {
            model: "RX",
            name: "RX350",
            compl: "F SPORT",
            engine: "2.4 бензин, 8 АТ, 4WD, 279 лс",
            price: "10 200 000 ₽",
            opt_show: false,
            opt_title: "Дополнительные опции F SPORT:",
            options: [
                'Легкосплавные затемненные диски F SPORT 21";',
                "Дизайн экстерьера F SPORT: передний и задний бампер, решётка радиатора, логотип на передних крыльях;",
                "усиленная тормозная система F SPORT;",
                "Обивка салона кожей с вставками из аллюминия;",
                "Передние сиденья спортивной формы F SPORT;",
                "Рулевое колесо F SPORT с кожаной отделкой, лепестками переключения и подогревом;",
                "Цифровая панель приборов F SPORT",
            ],
        },
        {
            model: "RX",
            name: "RX350",
            compl: "Premium",
            engine: "2.4 бензин, 8 АТ, 4WD, 279 лс",
            price: "10 200 000 ₽",
            opt_show: false,
        options: [
                'Легкосплавные затемненные диски F SPORT 21";',
                "Дизайн экстерьера F SPORT: передний и задний бампер, решётка радиатора, логотип на передних крыльях;",
                "усиленная тормозная система F SPORT;",
                "Обивка салона кожей с вставками из аллюминия;",
                "Передние сиденья спортивной формы F SPORT;",
                "Рулевое колесо F SPORT с кожаной отделкой, лепестками переключения и подогревом;",
                "Цифровая панель приборов F SPORT",
            ],
        },
        // LX500D
        {
            model: "LX",
            name: "LX500D",
            compl: "Executive",
            engine: "3.4 дизель, 10 АТ, 4WD, 299 лс",
            price: "19 650 000 ₽",
            opt_show: false,
            options: [
                'Легкосплавные диски 20";',
                "Люк с электроприводом;",
                "Обивка сидений кожей Semi-Aniline из полуанилиновой кожи;",
                'Цифровая комбинированная панель приборов 8";',
                "Apple CarPlay и Android Auto; ",
                "Вентиляция сидений первого ряда;",
                "Гидропневматическая подвеска с регулировкой высоты кузова (AHC);",
                "Система визуального и звукового оповещения об объектах при движении задним ходом (RCTA);",
                "Адаптивная система фар головного света с функцией переключения дальнего света на ближний с затемнением зоны «ослепления» встречного автомобиля (AHS);",
                "Передние и задние датчики парковки с функцией автоматического торможения и функцией предупреждения о пешеходах (PKSB);",
                "Система предупреждения столкновения с функцией автоматического торможения (PCS);",
                "Система оповещения о смене полосы движения (LDA);",
                "Система удержания в полосе движения (LTA);",
                "Динамический круиз-контроль (DRCC);",
                "Адаптивная система регулировки жесткости подвески (AVS)",
            ],
        },
        {
            model: "LX",
            name: "LX500D",
            compl: "F SPORT",
            engine: "3.4 дизель, 10 АТ, 4WD, 299 лс",
            price: "20 150 000 ₽",
            opt_show: false,
            opt_title: "Дополнительно к комплектации Executive:",
            options: [],
        },
        {
            model: "LX",
            name: "LX500D",
            compl: "Luxury",
            engine: "3.4 дизель, 10 АТ, 4WD, 299 лс",
            price: "20 150 000 ₽",
            opt_show: false,
            opt_title: "Дополнительно к комплектации Executive:",
            options: [
                "Аудиосистема объемного звучания Mark Levinson 25 динамиков;",
                "Проекционный дисплей (HUD);",
                'Легкосплавные диски 22";',
                "Вентиляция сидений второго ряда;",
                "Доводчики всех дверей; ",
                "Беспроводное зарядное устройство",
            ],
        },
        // LX600
        // {
        //   model: 'LX',
        //   name: 'LX600',
        //   compl: 'Executive',
        //   engine: '3.5 бензин, 10 АТ, 4WD, 415 лс',
        //   price: '20 540 000 ₽',
        //   opt_show: false,
        //   options: [
        //     'Легкосплавные диски 20";',
        //     'Обивка сидений кожей Semi-Aniline из полуанилиновой кожи;',
        //     'Цифровая комбинированная панель приборов 8";',
        //     'Apple CarPlay и Android Auto;',
        //     'Вентиляция сидений первого ряда;',
        //     'Гидропневматическая подвеска с регулировкой высоты кузова (AHC);',
        //     'Система визуального и звукового оповещения об объектах при движении задним ходом (RCTA);',
        //     'Адаптивная система фар головного света с функцией переключения дальнего света на ближний с затемнением зоны «ослепления» встречного автомобиля (AHS);',
        //     'Передние и задние датчики парковки с функцией автоматического торможения и функцией предупреждения о пешеходах (PKSB);',
        //     'Система предупреждения столкновения с функцией автоматического торможения (PCS);',
        //     'Система оповещения о смене полосы движения (LDA);',
        //     'Система удержания в полосе движения (LTA);',
        //     'Динамический круиз-контроль (DRCC);',
        //     'Адаптивная система регулировки жесткости подвески (AVS);',
        //     'Режимы движения по бездорожью Multi Terrain Select (6 режимов - АВТО, ГРЯЗЬ, ПЕСОК, ВЯЗКАЯ ГРЯЗЬ, ГЛУБОКИЙ СНЕГ, КАМНИ)',
        //   ]
        // },
        {
            model: "LX",
            name: "LX600",
            compl: "Luxury",
            engine: "3.5 бензин, 10 АТ, 4WD, 415 лс",
            price: "19 990 000 ₽",
            opt_show: false,
            opt_title: "Дополнительно к комплектации Executive:",
            options: [
                "Аудиосистема объемного звучания Mark Levinson 25 динамиков;",
                "Проекционный дисплей (HUD);",
                'Легкосплавные диски 22";',
                "Вентиляция сидений второго ряда;",
                "Доводчики всех дверей;",
                "Беспроводное зарядное устройство",
            ],
        },
        {
            model: "LX",
            name: "LX600",
            compl: "F SPORT",
            engine: "3.5 бензин, 10 АТ, 4WD, 415 лс",
            price: "20 400 000 ₽",
            opt_show: false,
            opt_title: "Дополнительные опции F SPORT:",
            options: [
                'Легкосплавные затемненные диски F SPORT 21";',
                "Дизайн экстерьера F SPORT: передний и задний бампер, решётка радиатора, логотип на передних крыльях;",
                "усиленная тормозная система F SPORT;",
                "Обивка салона кожей с вставками из аллюминия;",
                "Передние сиденья спортивной формы F SPORT;",
                "Рулевое колесо F SPORT с кожаной отделкой, лепестками переключения и подогревом;",
                "Цифровая панель приборов F SPORT",
            ],
        },
        {
            model: "LX",
            name: "LX600",
            compl: "VIP",
            engine: "3.5 бензин, 10 АТ, 4WD, 415 лс",
            price: "22 950 000 ₽",
            opt_show: false,
            opt_title: "Дополнительно к комплектации Luxury:",
            options: [
                "Правое сидение второго ряда OTTOMAN с электрорегулировкой в 6 направлениях, электрорегулировкой откидной подножки, положения кресла переднего пассажира, памятью в двух положениях и массажем;",
                "Левое сидение второго ряда представительского класса с электрорегулировкой в 6 направлениях, памятью в двух положениях и массажем;",
                "Отделка салона кожей semi-aniline с ромбовидной прострочкой;",
                "Подлокотник второго ряда сидений с блоком управления климат-контролем, регулировкой сидений и откидной подножки, функцией массажа и вентиляции;",
                'Два 11.6" LCD монитора высокого разрешения для пассажиров 2 ряда (RSE)',
            ],
        },
        {
            model: "LX",
            name: "LX600",
            compl: "VIP Black",
            engine: "3.5 бензин, 10 АТ, 4WD, 415 лс",
            price: "23 190 000 ₽",
            opt_show: false,
            opt_title: "Дополнительно к комплектации VIP:",
            options: [
                "Затемненная решетка радиатора;",
                "Затемненная окантовка боковых окон;",
                "Черные боковые зеркала;",
                "Черные дверные ручки;",
                "Черные рейлинги на крыше",
            ],
        },
        // ES250
        // {
        //   model: 'ES',
        //   name: 'ES250',
        //   compl: 'Advance',
        //   engine: '2.5 бензин, 8 АТ, 2WD, 200 лс',
        //   price: '6 890 000 ₽',
        //   opt_show: false,
        //   options: [
        //     'Люк с электроприводом;',
        //     'Обивка сидений кожей;',
        //     'Многофункциональный цветной дисплей 7" на панели приборов;',
        //     'Apple CarPlay и Android Auto;',
        //     'Датчик света;',
        //     'Датчик дождя;',
        //     'Светодиодные фары ближнего и дальнего света с функцией освещения траектории поворота;',
        //     'Камера заднего вида с динамической разметкой;',
        //     'Аудиосистема c 10 динамиками, DAB, USB+AUX, Bluetooth®'
        //   ]
        // },
        // {
        //   model: 'ES',
        //   name: 'ES250',
        //   compl: 'Premium',
        //   engine: '2.5 бензин, 8 АТ, 2WD, 200 лс',
        //   price: '7 450 000 ₽',
        //   opt_show: false,
        //   opt_title: 'Дополнительно к комплектации Advance:',
        //   options: [
        //     'комплекс систем активной безопасности Lexus Safety System+;',
        //     'электропривод крышки багажника;',
        //     'систему вентиляции передних сидений;'
        //   ]
        // },
        // NX200
        // {
        //   model: 'NX',
        //   name: 'NX200',
        //   compl: 'Comfort',
        //   engine: '2.0 бензин, CVT, 2WD, 149 лс',
        //   price: '5 690 000 ₽',
        //   opt_show: false,
        //   options: [
        //     'Камера заднего вида с динамическими линиями разметки;',
        //     'Apple CarPlay и Android Auto;',
        //     'Комбинированнаяя обивка сидений;',
        //     'Комплекс систем активной безопасности Lexus Safety System + 3.0: система предупреждения столкновения с функцией автоматического торможения (PCS); система оповещения о смене полосы движения (LDA); система удержания в полосе движения (LTA); адаптивный круиз-контроль (DRCC);',
        //     'Система доступа в автомобиль Smart Entry и запуска двигателя кнопкой Push start',
        //   ]
        // },
        // NX250
        // {
        //   model: 'NX',
        //   name: 'NX250',
        //   compl: 'Executive',
        //   engine: '2.5 бензин, 8 АТ, 4WD, 200 лс',
        //   price: '7 590 000 ₽',
        //   opt_show: false,
        //   options: [
        //     'Беспроводное зарядное устройство;',
        //     'Apple CarPlay и Android Auto;',
        //     'Электрорегулировка передних сидений по 8 направлениям;',
        //     'Камера заднего вида с динамическими линиями разметки;',
        //     'Светодиодные передние фары ближнего и дальнего света с двумя линзами;',
        //     'Комплекс систем активной безопасности Lexus Safety System + 3.0: система предупреждения столкновения с функцией автоматического торможения (PCS); система оповещения о смене полосы движения (LDA); система удержания в полосе движения (LTA); адаптивный круиз-контроль (DRCC);',
        //     'Система доступа в автомобиль Smart Entry и запуска двигателя кнопкой Push start',
        //   ]
        // },
        // GX460
        // {
        //   model: 'GX',
        //   name: 'GX460',
        //   compl: 'Executive',
        //   engine: '4.6 бензин, 6 АТ, 4WD, 296 лс',
        //   price: '11 990 000 ₽',
        //   opt_show: false,
        //   options: [
        //     'Мультифункциональный дисплей 10,3";',
        //     'Apple CarPlay и Android Auto;',
        //     'Люк с электроприводом;',
        //     'Вставки в интерьере "Черное дерево с лазерной гравировкой";',
        //     'Кожаная обивка сидений Semi aniline;',
        //     'Подогрев 1-го и 2-го рядов сидений;',
        //     'Интеллектуальная система доступа в автомобиль Smart Entry и запуск двигателя Push Start;',
        //     'Датчик света;',
        //     'Система стабилизации положения кузова (KDSS);',
        //     'Дифференциал повышенного трения TORSEN® (LSD);',
        //     'Пневматическая задняя подвеска'
        //   ]
        // },
        {
            model: "GX",
            name: "GX460",
            compl: "Premium",
            engine: "4.6 бензин, 6 АТ, 4WD, 296 лс",
            price: "12 450 000 ₽",
            opt_show: false,
            opt_title: "Дополнительно к комплектации Executive:",
            options: [
                "Комплекс систем активной безопасности Lexus Safety System + (система предупреждения столкновения с функцией автоматического торможения (PCS): система оповещения о смене полосы движения (LDA); cистема автоматического переключения дальнего света на ближний (AHB); адаптивный круиз-контроль (ACC);система контроля усталости водителя (SWS));",
                "Третий ряд сидений разделенный в пропорции 50:50 с электроприводом складывания;",
                "Аудиосистема объемного звучания Mark Levinson",
            ],
        },
        */

	/*
	// RX450H
	{
		model: "RX",
		name: "RX450H",
		compl: "Comfort",
		engine: "2.5 гибрид, CVT, 4WD, 309 л.с.",
		price: "9 150 000 ₽",
		opt_show: false,
	},
	{
		model: "RX",
		name: "RX450H",
		compl: "Executive",
		engine: "2.5 гибрид, CVT, 4WD, 309 л.с.",
		price: "9 150 000 ₽",
		opt_show: false,
	},
	{
		model: "RX",
		name: "RX450H",
		compl: "F Sport",
		engine: "2.5 гибрид, CVT, 4WD, 309 л.с.",
		price: "9 150 000 ₽",
		opt_show: false,
	},
	{
		model: "RX",
		name: "RX450H",
		compl: "Luxury",
		engine: "2.5 гибрид, CVT, 4WD, 309 л.с.",
		price: "9 150 000 ₽",
		opt_show: false,
	},
	{
		model: "RX",
		name: "RX450H",
		compl: "Premium",
		engine: "2.5 гибрид, CVT, 4WD, 309 л.с.",
		price: "9 150 000 ₽",
		opt_show: false,
	},
	{
		model: "RX",
		name: "RX450H",
		compl: "Prestige",
		engine: "2.5 гибрид, CVT, 4WD, 309 л.с.",
		price: "9 150 000 ₽",
		opt_show: false,
	},
	// RX350H
	{
		model: "RX",
		name: "RX350H",
		compl: "Executive",
		engine: "2.5 гибрид, CVT, 4WD, 250 л.с.",
		price: "9 150 000 ₽",
		opt_show: false,
	},
	{
		model: "RX",
		name: "RX350H",
		compl: "F SPORT",
		engine: "2.5 гибрид, CVT, 4WD, 250 л.с.",
		price: "9 150 000 ₽",
		opt_show: false,
	},
	// RX500H
	{
		model: "RX",
		name: "RX500H",
		compl: "Perfomance",
		engine: "2.4 гибрид, AT, 4WD, 371 л.с.",
		price: "9 150 000 ₽",
		opt_show: false,
	},
	// TX
	{
		model: "TX",
		name: "TX350",
		compl: "Premium",
		engine: "2.4 бензин, AT, 4WD, 279 л.с.",
		price: "7 590 000 ₽",
		opt_show: false,
		opt_title: "Дополнительно к комплектации Executive:",
	},
	{
		model: "TX",
		name: "TX350",
		compl: "F SPORT",
		engine: "2.4 бензин, AT, 4WD, 279 л.с.",
		price: "7 590 000 ₽",
		opt_show: false,
		opt_title: "Дополнительно к комплектации Executive:",
	},

 */
];
