<template>
	<form
		class="callback-form callbackwidget-call-form"
		novalidate
		@submit.prevent="submitThis"
		:class="[device_platform, { horizontal: horizontal }, form_class]"
	>
		<div class="callback-form__container">
			<input
				name="tel"
				type="tel"
				class="callback-form__input"
				placeholder="Ваш телефон"
				v-model="phone"
				ref="phone"
				v-mask
				:pattern="'.*[0-9]{1}.*[0-9]{3}.*[0-9]{3}.*[0-9]{4}'"
				required
			/>
			<button
				type="submit"
				:disabled="!agreed"
				class="button button_theme_primary callback-form__button"
				:class="[{ 'button_disabled': !agreed }]"
				ref="submitter"
			>
				<slot></slot>
			</button>
		</div>

		<div
			class="agreement"
			v-if="CONSTANTS.need_agreement"
		>
			<checkbox v-model="acceptance">
				<slot name="agreement">
					<span class="agreement__text">
						<span class="agreement__link" @click.prevent="getAgreement">Согласие</span>
						на обработку персональных данных
					</span>
				</slot>
			</checkbox>
		</div>
	</form>
</template>

<script>
import Mixin from "@/common/mixin";
import Mask from "@/common/mask";
import Checkbox from "../checkbox";

export default {
	name: "callback-input",
	components: { Checkbox },
	directives: { Mask },
	mixins: [Mixin],
	props: ["horizontal", "data", "form_class", "focusOnMount"],
	data() {
		return {
			acceptance: true,
			phone: "",
		};
	},
	computed: {
		agreed() {
			return this.acceptance && this.phoneValidate;
		},
		phoneValidate() {
			let regexp = /\d/g;
			let res = this.phone.match(regexp);
			if (res && res.length === 11 && (res[1] == 9 || res[1] == 4)) {
					return true;
			} else {
					return false;
			}
		}
	},
	mounted() {
		if (this.focusOnMount) {
			this.$refs.phone.focus();
		}
	},
	methods: {
		getAgreement() {
			this.$emit("getAgreement");
		},
		submitThis(event) {
			if (
				this.$refs.phone.validity.valueMissing ||
				!this.$refs.phone.validity.valid
			) {
				this.$refs.phone.setCustomValidity("Введите номер телефона");
			}
			if (
				!this.$refs.phone.validity.valueMissing &&
				!this.$refs.phone.validity.patternMismatch
			) {
				this.$refs.phone.setCustomValidity("");
			}
			if (this.agreed && this.$el.reportValidity()) {
				if (typeof ckForms !== "undefined") {
					ckForms.send(this.$el);
				}
				this.$emit("callBack", {
					phone: this.phone,
					data: this.data,
				});
				if(!this.data || this.data.form != 'cta') {
					ym(8467762,'reachGoal','send_form');
				}
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import "../../styles/constants.scss";

.mobile {
	.callback-form {
		&__container {
			align-items: center;
			min-width: unset;
		}
	}

	&.contacts {
		.agreement {
			left: 0;
		}
	}
}

.callback-form {
	&.horizontal {
		width: 50%;
	}
	&__container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		max-width: 395px;
		min-width: 290px;
		width: 100%;
	}

	&__input {
		width: 100%;
		height: 55px;
		padding: 0 20px;
		margin-right: 10px;
		margin-bottom: 10px;
		text-align: left;
		font-size: 16px;
		color: #000;
		background-color: transparent;
		border: 1px solid #000;

		&::placeholder {
			font-weight: 400;
			font-size: 16px;
			color: gray;
			padding: 0;
			overflow: visible;
		}
	}

	&__button {
		height: 55px;
		width: 100%;
		&_disabled {
			background: #c2c2c2;
			cursor: default;
		}
	}
}

.agreement {
	display: flex;
	justify-content: start;
	position: relative;
	align-items: center;
	margin-top: 10px;

	&__text {
		padding-left: 25px;
		font-size: 10px;
		text-align: left;
	}

	&__link {
		color: black;
		text-decoration: underline;
		cursor: pointer;
	}
}

.callback-form.horizontal {
	.callback-form {
		&__container {
			flex-direction: row;
			max-width: none;
		}
	}
	@media (max-width: 890px) {
		.callback-form {
			&__container {
				flex-direction: column;
				max-width: none;
			}
			&__input {
				margin-right: 0;
			}
		}
	}
}
</style>
