<template>
  <section class="block-trade-in trade-in">
    <div class="trade-in__container container">
      <div class="trade-in__content">
        <h2 class="trade-in__title">
          ЛЕГКИЙ ОБМЕН
        </h2>
        <p class="trade-in__subtitle">
          Обменяйте свой кредитный автомобиль
          <br>любого бренда на новый Lexus.
        </p>
        <button class="trade-in__button button button_theme_primary"
          @click="getCall({type: 'sale', form: 'trade-in', title: 'Оставить заявку'})"
        >
          Оставить заявку
          <!-- <img src="@/images/temporary/lights.png" alt="" class="trade-in__lights"> -->
        </button>
      </div>
    </div>

    <div class="trade-in__image" />

    <!--<img src="@/images/temporary/hearts.png" class="trade-in__temporary">-->
  </section>
</template>

<script>
import Mixin from "../common/mixin";

export default {
	name: "block-trade-in",
	mixins: [Mixin],
}
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.trade-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
	height: 790px;
	z-index: 5;
  //background: url("../images/background/bg-trade-in.jpg") no-repeat;
  //background-position: 150% 0;

  &:after{
    position: absolute;
    content:'';
    right: 0;
    width: 50%;
    height: 100%;
    background: url("../images/background/bg-trade-in.jpg") no-repeat center/cover;
  }

  &__content {
    width: 615px;
    padding: 75px 220px 75px 0;
    background: white;
  }
  &__title {
    font-family: "Favorit Bold", sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
    text-transform: uppercase;
    padding: 5px;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    margin-bottom: 25px;
  }
  &__subtitle {
    font-family: "Favorit Light", sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 25px;
  }
  &__button {
		height: 50px;
    width: 100%;
    position: relative;
  }
  &__lights {
    position: absolute;
    top: 100%;
    left: 0;
  }
  &__image {
    display: none;
  }
 /* &__temporary {
    position: absolute;
    top: 100px;
    left: 133px;
  }*/
}

@media (max-width: 1320px) {
  .trade-in {
    background-position-x: 130px;
  }
}

@media (max-width: 1020px) {
  .trade-in {
    display: flex;
    flex-direction: column-reverse;
    background: none;
    height: auto;

    &:after{
      display: none;
    }

    &__content {
      width: 100%;
      padding: 75px 40px;
    }
    &__image {
      display: block;
      width: 100%;
      height: 500px;
      background: url("../images/background/bg-trade-in.jpg") no-repeat right;
      background-size: cover;
    }
    /*&__temporary {
      display: none;
    }*/
  }
}

</style>
