<template>
  <section class="block-offer offer container">
    <div class="offer__wrapper">
      <div class="offer__content">
        <img
          class="offer__icon"
          :src="icon"
          alt="УСПЕЙТЕ ДО ПОВЫШЕНИЯ УТИЛЬСБОРА!"
        />
        <p class="offer__title"><span>ДНИ ПРОДАЖ</span> LEXUS <nobr>ДО {{ getDate() }}!</nobr></p>
        <p class="offer__subtitle"></p>
      </div>

      <callback-input :horizontal="true" v-on="$listeners" class="offer__form"
        >УЗНАТЬ ПОДРОБНЕЕ</callback-input
      >
    </div>
  </section>
</template>

<script>
import Mixin from "@/common/mixin";
import CallbackInput from "./callback-form/callback-input";
import { formatDate } from '@/common/date';

const bell = require("../images/icons/bell.svg");

export default {
  name: "block-offer",
  mixins: [Mixin],
  components: { CallbackInput },
  data() {
    return {
      icon: bell,
    };
  },
  methods: {
    getAgreement() {
      this.$emit("getAgreement");
    },
    getDate () {
			const NOW = new Date();
			const ONE_DATE = new Date('2024, 11, 14, 23:50');
			const TWO_DATE = new Date('2024, 11, 18, 23:50');

			const dynamicDate = ONE_DATE <= NOW ? TWO_DATE : ONE_DATE;
			return formatDate(dynamicDate, 'shortNumber');
		},
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";

.offer {
  width: 100%;
  padding-top: 40px;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #d7d7d7;
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 25px;
  }
  &__title {
    font-family: "Favorit Bold";
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 800;
    span {
      color: #da3136;
    }
  }
  &__subtitle {
    font-size: 16px;
    text-transform: uppercase;
    b {
      font-family: "Favorit Bold";
    }
  }
}

@media (max-width: 640px) {
  .offer {
    &__wrapper {
      flex-direction: column;
    }
    &__content {
      margin-bottom: 15px;
      column-gap: 0px;
    }
    &__icon {
      width: calcFontSize(65, 93, 320, 640);
    }
    &__title {
      font-size: calcFontSize(14, 30, 320, 640);
    }
    &__form.callback-form {
      width: 100%;
    }
  }
}
</style>
