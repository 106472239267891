<template>
  <section class="block-video">
    <div class="block-video__container container">
      <div class="block-video__title">АКЦИЯ НА <b>LEXUS</b> В РОЛЬФ</div>
      <button 
        class="block-video__btn button button_theme_secondary"
        @click="getCall({ type: 'sale', form: 'main', title: 'Заказать обратный звонок' })"
      >
        Заказать обратный звонок
      </button>
    </div>

    <!-- <div class="block-video__video-wrapper">
      <video class="block-video__video-file" loop muted autoplay playsinline>
        <source src='../video/lexus.mp4' type="video/mp4">
      </video>
    </div> -->

    <!-- <img src="@/images/temporary/gift1.png" class="block-video__temporary"> -->
  </section>
</template>

<script>
import mixin from '../common/mixin';

export default {
  mixins: [mixin],
}
</script>

<style lang="scss" scoped>

.block-video {
  width: 100%;
  position: relative;
  height: calc(100vw * 700 / 1920);
  background: rgba(0,0,0, .5) url('../images/background/bg-offer.jpg') no-repeat center/cover;
  display: flex;
  flex-direction: column;
  padding-top: 50px;

  &__title {
    color: #000;
    font-size: 55px;
    margin-bottom: 20px;
  }

  &__btn {
    height: 59px;
    padding-right: 100px;
  }

  // &__video-wrapper {
  //   overflow: hidden;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   position: absolute;
  //   z-index: -1;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  // &__temporary {
  //   position: absolute;
  //   right: 35px;
  //   bottom: -114px;
  // }
}

@media (max-width: 800px) {
  .block-video {
    height: calc(100vw * 850 / 800);
    padding-top: 15px;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      font-size: 6.5vw;
      margin-bottom: 3vw;
    }

    &__btn {
      font-size: 14px;
      height: auto;
      padding: 10px 22px;
    }

    // &__video-file {
    //   width: 100%;
    //   height: 100%;
    // }
    
    // &__temporary {
    //   display: none;
    // }
  }
}

</style>