<template>
  <div class="stock-slider" v-if="renderComponent">
    <div class="about__gallery">
      <div class="about__arrow slidePrev"></div>
      <div class="about__arrow slideNext"></div>
      <div class="swiper-container about-swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="car in cars_data"
            :key="car.name + car.compl + car.price + car.engine"
          >
            <div class="slide__model-img">
              <img :src="car.img" :alt="car.name" />
            </div>
            <ul class="slide__list">
              <li class="slide__list-item">{{ car.name }}</li>
              <li class="slide__list-item">{{ car.compl }}</li>
              <li class="slide__list-item">{{ car.engine }}</li>
            </ul>
            <!-- <button
              class="stock-table__btn button button-dark-blue st_slider"
              @click="$emit('call', 'complectation_price')"
            >
              от <span class="line-through">{{ car.price }}</span>
            </button> -->
            <button
              v-if="car.options.length"
              class="stock-table__btn button button_theme_primary st_slider"
              v-on:click="status = !status"
              @click="id_car = car.id"
            >
              ОПЦИИ
            </button>
          </div>
        </div>
      </div>

      <div class="" v-for="(car, i) in cars_data" :key="i">
        <div
          v-if="car.id === id_car"
          :class="card__features"
          @click="status = !status"
        >
          <div class="card__features_list">
            <div class="card__features-close t-modal-close"></div>
            <div class="card__features-text">ОПЦИИ</div>
            <div 
              v-for="option in car.options" 
              class="card__features-item" 
              v-html="option"
            ></div>
          </div>
        </div>
      </div>

      <!-- <div class="stock-slider__pagination">
        <div class="swiper-pagination"></div>
      </div> -->
      <button
        class="stock-table__btn button button_theme_primary st_slider"
        @click="$emit('call', 'complectation_bron')"
      >
        УЗНАТЬ ЦЕНУ ПО АКЦИИ
      </button>
    </div>
  </div>
</template>

<script>
import Mixin from "../common/mixin";
import Swiper from "swiper";

export default {
  name: "stock-slider",
  props: ["cars_data"],
  mixins: [Mixin],
  data() {
    return {
      swiper: null,
      status: true,
      id_car: 0,
      renderComponent: true,
    };
  },
  mounted() {
    this.initSwiper();
  },
  watch: {
    cars_data() {
      this.swiper.destroy();
      this.swiper = null;
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
  },
  computed: {
    card__features: function () {
      if (this.status === true) {
        return "card__features disnone";
      } else {
        return "card__features disflex";
      }
    },
  },
  methods: {
    initSwiper() {
      // if (this.swiper) return;
      this.swiper = new Swiper(`.about-swiper`, {
        simulateTouch: true,
        slides: this.cars_data.length,
        navigation: {
          nextEl: `.slideNext`,
          prevEl: `.slidePrev`,
        },
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          dynamicMainBullets: 5,
          clickable: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.line-through {
  text-decoration: line-through;
}

.swiper {
  &-container {
    overflow: hidden;
  }
}

.stock-slider {
  &__pagination {
    max-width: 100%;
    width: 290px;
    margin: 0 auto;
    overflow: hidden;
    height: 10px;
    position: relative;
    padding: 10px 0;

    .swiper-pagination {
      width: 523px !important;
      top: 50%;
      height: 10px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }

  .st_slider {
    max-width: 100%;
    width: 100%;
    margin: 7px 0;
    font-weight: 700;
    padding: 12px 20px;
  }
}

.about__gallery {
  position: relative;
}

.about__arrow {
  position: absolute;
  width: 16px;
  height: 32px;
  transform: translateY(-30%);
  z-index: 5;
  cursor: pointer;
  outline: none;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  top: 75px;

  &:active {
    opacity: 0.5;
  }

  &.slidePrev {
    background: url("../images/utils/arrow-prev.svg") no-repeat center;
    left: 0;
  }

  &.slideNext {
    background: url("../images/utils/arrow-next.svg") no-repeat center;
    right: 0;
  }
}

.slide {
  &__model-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 22px;
    img {
      width: 300px;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 10px;
  }

  &__list-item {
    background-color: #fafafa;
    padding: 10px 20px;
    margin-top: 10px;
    // font-size: 14px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.card__features {
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
 position: fixed;
 z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  &-text {
    text-align: center;
    margin-bottom: 10px;
  }
}
.disnone {
  display: none;
}
.disflex {
  display: flex;
}
.card__features_list {
  max-width: 95%;
  width: 100%;
  position: relative;
  padding: 40px;
  background: #fff;
  border-radius: 5px;
  max-height: 95vh;
  overflow: auto;
}

.t-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 22px;
  width: 22px;
  cursor: pointer;
  background: url("../images/utils/close.svg") no-repeat center/contain;
}
.card__features-item {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.card__features-item::before {
  content: "";
  min-width: 4px;
  min-height: 4px;
  border-radius: 50%;
  background: #001a37;
  margin-right: 5px;
}
</style>
