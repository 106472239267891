<template>
  <article class="card" :class="[device_platform]" :id="active_car.model">
    <!-- <img
        v-if="snowImgNum"
        :src="getSnowImg()" alt=""
        class="card__snowImg"
        :class="[{'card__snowImg--2': snowImgNum == 2}, {'card__snowImg--rotated': isImgRotated()}]"
    > -->
    <!-- <img 
      v-if="active_car.model === 'rx_new'"
      src="@/images/temporary/heart1.png" 
      class="card__heart card__heart--1"
    >
    <img 
      v-if="active_car.model === 'lx'"
      src="@/images/temporary/heart2.png" 
      class="card__heart card__heart--2"
    >
    <img 
      v-if="active_car.model === 'gx'"
      src="@/images/temporary/heart1.png" 
      class="card__heart card__heart--3"
    >
    <img 
      v-if="active_car.model === 'nx'"
      src="@/images/temporary/heart2.png" 
      class="card__heart card__heart--4"
    >
    <img 
      v-if="active_car.model === 'es'"
      src="@/images/temporary/heart1.png" 
      class="card__heart card__heart--5"
    > -->
    <div class="card__body">
      <div class="card__content">
        <h3 class="card__title card__title--desktop">
          <template v-if="active_car.model === 'lc'">
            Toyota Land Cruiser
          </template>
          <template v-else>
            <span v-if="active_car.model === 'lx500d'">Lexus LX 500 D</span>
            <span v-if="active_car.model === 'lx600'">Lexus LX 600</span>
            <span v-if="active_car.model !== 'lx600' && active_car.model !== 'lx500d'">Lexus {{ active_car.model.toUpperCase().replace('_', ' ') }}</span>
            <p 
              class="card__title-advantage" 
              v-if="active_car.advantage"
            >
              Выгода до <b>{{ active_car.advantage }} ₽</b>
            </p>
            <!-- <p 
              class="card__title-advantage" 
              v-else
            >
             по акции в <b>РОЛЬФ</b>
            </p> -->

            <span v-if="active_car.model === 'es'" class="card__title-label">С НДС</span>
          </template>
        </h3>
        <button
          class="card__button button button_theme_secondary"
          @click="handleClick"
        >
          <template v-if="active_car.model === 'new_nx'"
            >Оформить заказ</template
          >
          <template v-if="active_car.model === 'rx'">Подробности в отделе продаж</template>
          <template v-else>УЗНАТЬ ЦЕНУ ПО АКЦИИ</template>
        </button>

        <ul class="card__advantages">
          <li
            class="card__advantage"
            v-if="active_car.key_to_key"
            @click="handleClick"
          >
            Легкий обмен<br />
            <b>«‎Ключ в ключ»</b>
          </li>
          <li
            class="card__advantage"
            v-if="active_car.buy_in_stock"
            @click="handleClick"
          >
            КУПИТЬ<br />
            <b>ПО АКЦИИ</b>
          </li>
          <li
            class="card__advantage"
            v-if="active_car.buyback"
            @click="handleClick"
          >
            Спец. цена при <br />
            <b>трейд-ин</b>
          </li>
          <li
            class="card__advantage"
            v-if="active_car.casco"
            @click="handleClick"
          >
            КАСКО<br />
            <b>ЗА НАШ СЧЕТ</b>
          </li>
          <li
            class="card__advantage"
            v-if="active_car.add_equip"
            @click="handleClick"
          >
            Доп. оборудование<br />
            <b>в подарок</b>
          </li>

          <li class="card__advantage" @click="handleClick">
            <b>Официальная гарантия</b> РОЛЬФ 2 года или 100 000 км
          </li>

          <li class="card__advantage" @click="handleClick">
            Постановка на учет <br />в ГИБДД <b>в подарок</b>
          </li>

          <li
            class="card__advantage"
            v-if="active_car.extra_trade_in"
            @click="handleClick"
          >
            <b>ТРЕЙД-ИН</b> <br />
            ЗА 2 ЧАСА
          </li>

          <li class="card__advantage" @click="scrollTo('stock')">
            <b>Комплектации и цены</b>
          </li>
        </ul>
      </div>

      <div class="card__image">
        <h3 class="card__title card__title--mobile">
          <template v-if="active_car.model === 'lc'"
            >Toyota Land Cruiser</template
          >
          <template v-else
            >
            <span v-if="active_car.model === 'lx500d'">Lexus LX 500 D</span>
            <span v-if="active_car.model === 'lx600'">Lexus LX 600</span>
            <span  v-if="active_car.model !== 'lx600' && active_car.model !== 'lx500d'">Lexus {{ active_car.model.toUpperCase().replace('_', ' ') }}</span>
            <p 
              class="card__title-advantage" 
              v-if="active_car.advantage"
            >
              Выгода до <b>{{ active_car.advantage }} ₽</b>
            </p>
            <!-- <p 
              class="card__title-advantage" 
              v-else
            >
              по акции в <b>РОЛЬФ</b>
            </p> -->
            <span v-if="active_car.model === 'es'" class="card__title-label">С НДС</span>
          </template>
        </h3>

        <div
          class="car__swiper swiper-container"
          :class="`car__swiper_${active_car.model}`"
        >
          <div class="car__swiper-wrapper swiper-wrapper">
            <div
              class="car__swiper-slide swiper-slide"
              v-for="img in imgCount()"
              :key="img"
            >
              <img
                class="car__swiper-slide-img"
                :src="getImg(img)"
                :alt="`Фото ${active_car.model}`"
                @click="openGallery(img)"
              />
            </div>
          </div>

          <div
            class="car__swiper-arrow-prev swiper-button-prev"
            :class="`car__swiper-arrow-prev_${active_car.model}`"
          ></div>
          <div
            class="car__swiper-arrow-next swiper-button-next"
            :class="`car__swiper-arrow-next_${active_car.model}`"
          ></div>

        </div>

        <template v-if="colorsLength > 1">
          <div v-if="activeColor" class="car__colors">
            <div
                v-for="(color, i) of colors" :key="i"
                class="car__colors-item"
                :class="{'car__colors-item--active': color == activeColor}"
                :style="`background: ${carColors[i].hex}`"
                @click="setActiveColor(color)"
            ></div>
          </div>
      </template>

      </div>
    </div>

    <full-gallery
        v-if="isGalleryVisible"
        :imgs="galleryImgs()"
        :index="galleryIndex"
        @close="closeGallery"
    />

  </article>
</template>

<script>
import finance from "../common/finance";
import Mixin from "../common/mixin";
import Observer from "./observer";
import Swiper, {Navigation, Pagination} from "swiper";
import {Lazy} from "swiper/dist/js/swiper.esm";
import FullGallery from './full-gallery.vue';
import CarColors from "@/common/car-colors";

export default {
  mixins: [finance, Mixin],
  props: ["cars", "amounts", "snowImgNum"],
  components: { Observer, Swiper, FullGallery },
  data() {
    const first_car = this.cars[0];
    const carColors = CarColors[first_car.model]
    const activeColor = carColors ?
        carColors[0].color
        : false;
    const colors = carColors ?
        carColors.map( item => item.color)
        : false;
    const colorsLength = colors.length;
    return {
      active_car: first_car,
      popup: true,
      swiper: null,
      carColors,
      activeColor,
      colors,
      colorsLength,
      isGalleryVisible: false,
      galleryIndex: 0,
    };
  },
  created() {
    this.cars.forEach((car) => {
      let car_name = car.model.toUpperCase() + car.complectation.toUpperCase();
      car.amount = this.amounts[car_name];
    });
  },
  mounted() {
    this.initSwiper(this.active_car.model);
  },
  methods: {
    initSwiper(car) {
      this.swiper = new Swiper(`.car__swiper_${car}`, {
        navigation: {
          nextEl: `.car__swiper-arrow-next_${car}`,
          prevEl: `.car__swiper-arrow-prev_${car}`,
        },
        // не меняется цвет с lazy
        // lazy: {
        //   loadOnTransitionStart: false,
        //   loadPrevNext: false,
        // },
        // modules: [Navigation, Lazy],
      });
    },
    getImg(img) {
      return this.activeColor ?
       require(`../images/cars/new/${this.active_car.model}/${this.activeColor}/${img}.jpg`)
       : require(`../images/cars/new/${this.active_car.model}/${img}.jpg`)
    },
    imgCount(){
      const colorObj = this.carColors.find(item => item.color == this.activeColor)
      return colorObj.imgs || this.active_car.images || 4
    },
    galleryImgs(){
      return Array( this.imgCount() ).fill(null)
          .map( (item, i) => this.getImg(i + 1) )
    },
    openGallery(i){
      this.galleryIndex = i - 1
      this.isGalleryVisible = true
    },
    closeGallery(){
      this.isGalleryVisible = false;
    },
    scrollTo(where) {
      this.$emit("scrollTo", where);
    },
    setActiveColor(color) {
      this.activeColor = color
      this.$nextTick(() => {
        this.swiper.update()
        this.swiper.slideTo(0)
      });
    },
    handleClick() {
      this.getCall({ type: "sale", form: "cars" });
    },
    isActiveCar(car) {
      return car.complectation === this.active_car.complectation;
    },
    getCarTitle(car) {
      const complectation =
        car.complectation === "Black Vision"
          ? "Black&#160;Vision"
          : car.complectation;

      if (car.model === "rx" && car.complectation === "350L") {
        return `Lexus ${car.model} ${complectation}`.toUpperCase() + " 7 МЕСТ";
      }

      if (car.model === "new_nx") {
        return `Новый Lexus NX ${complectation}`.toUpperCase();
      }

      if (car.model === "new_lx") {
        return `Новый Lexus LX ${complectation}`.toUpperCase();
      }

      if (car.model === "lc") {
        return `Toyota <br> Land Cruiser ${complectation}`.toUpperCase();
      }

      return `Lexus ${car.model} ${complectation}`.toUpperCase();
    },
    getCarImage(car) {
      if (car.new_image)
        return require(`../images/cars/new/${car.model.toLowerCase()}.jpg`);

      const imageFileName = `${car.model.toLowerCase()} ${car.complectation.toLowerCase()}`;
      if (
        car.model === "nx" ||
        car.model === "new_lx" ||
        car.model === "lc" ||
        car.model === "lx" ||
        car.model === "lx 600" ||
        car.model === "lx 500" ||
        car.model === "gx"
      ) {
        return require(`../images/cars/models/${imageFileName}.png`);
      }
      return require(`../images/cars/models/${imageFileName}.jpg`);
    },
    toggleActiveCar(car) {
      this.active_car = car;
      this.$emit("toggle-car", { ...car, img: this.getCarImage(car) });
    },
    isActive(complectation) {
      return complectation === this.active.complectation;
    },
    getSnowImg(){
      return require (`@/images/temporary/snow${this.snowImgNum}.png`)
    },
    isImgRotated(){
      return Math.round( Math.random() )
    }
  },
};
</script>

<style lang="scss">
@import "../styles/constants.scss";

img {
  max-width: 100%;
}

.card {
  width: 100%;
  background: #f7f7f7;
  padding: 50px;
  position: relative;

  &__snowImg {
    position: absolute;
    top: -36px;
    left: -36px;

    &--2 {
      top: -16px;
      left: -8px;
    }
  }

  &__body {
    display: flex;
  }
  &__content {
    width: 45%;
    margin-right: 80px;
  }
  &__complectation-title {
    margin: 0;
    padding: 20px 0;
    font-size: 16px;
  }
  &__complectation-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style-type: none;
  }
  &__complectation-item {
    width: 50%;
    padding: 7px 0;
  }
  &__complectation-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    outline: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    outline: none;
    &.active {
      font-weight: 700;
      border-color: black;
      border-color: black;
    }
    &:hover {
      text-decoration: underline;
      font-weight: bold;
    }
  }
  &__button {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    height: 59px;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    padding: 7px 0;
    border-top: 2px solid black;
    border-bottom: 2px solid black;

    &-price {
      font-size: 0.9em;
      text-transform: none;
      white-space: nowrap;
      margin-left: 0.5em;
    }

    .red{
      color: #ec1c24;
    }
    
    &-advantage {
      font-size: 0.7em;
      font-weight: 400;
      line-height: 1;
      color: #ec1c24;
      //align-self: flex-end;
    }
    &--mobile {
      display: none;
    }
  }
  &__image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  &__credit {
    text-transform: uppercase;
  }
  &__credit-title {
    display: block;
    font-size: 16px;
  }
  &__credit-value {
    font-size: 25px;
  }
  &__advantages {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    cursor: pointer;
  }
  &__advantage {
    width: 46%;
    margin: 2%;
    padding: 10px 0;
    text-transform: uppercase;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    b {
      font-family: "Favorit Bold", sans-serif;
    }
  }
  &__heart {
    position: absolute;
    z-index: 10;
    &--1 {
      top: -24px;
      right: -24px;
      width: 61.973px;
      height: 57.239px;
      transform: scaleX(-1);
    }
    &--2 {
      top: 0;
      left: 50%;
    }
    &--3 {
      top: -10px;
      right: 40%;
      width: 34.305px;
      height: 31.684px;
    }
    &--4 {
      top: -23px;
      left: -26px;
    }
    &--5 {
      top: 15px;
      right: 20px;
      width: 49.627px;
      height: 45.836px;
      transform: rotate(15.948deg);
    }
  }
}

.car {
  &__swiper {
    width: 600px;

    &-slide {
      min-height: 100%;
      max-height: 400px;

      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__colors {
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
    padding: 10px 0;

    &-item {
      width: 30px;
      height: 30px;
      border: 3px solid transparent;
      border-radius: 50%;
      transition: 0.3s;
      &:not(&--active) {
        cursor: pointer;
      }

      // &:only-child {
      //   opacity: 0;
      // }

      &--active {
        box-shadow: inset 0px 0px 1px red, inset 0px 0px 2px red, inset 0px 0px 3px red;
        scale: 0.98;
      }

      &:not(&--active):hover {
        scale: 1.1;
        box-shadow: 1px 1px 5px #555;
      }

      &:active {
        scale: 0.98;
        transition: 0.1s;
      }
    }
  }
}

#new_nx {
  .card__image {
    & img {
      width: 80%;
      align-self: center;
    }
  }
}

#lc {
  .card__button {
    background: #ff0022;
    border: 1px solid #ff0022;

    &:hover {
      background: #fff;
      border: 1px solid #000;
    }
  }
}

@media (max-width: 1300px) {
  .card {
    &__snowImg {
      position: absolute;
      top: calcFontSize(-22, -36, 800, 1300);
      left: calcFontSize(-22, -36, 800, 1300);

      &--2 {
        top: calcFontSize(-10, -17, 800, 1300);
        left: calcFontSize(-4, -6, 800, 1300);
      }
    }
  }
}
@media (max-width: 1150px) {
  .card {
    padding: 35px;

    &__advantage {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .car {
    &__swiper {
      width: 450px;
    }
  }
}

@media (max-width: 850px) {
  .card {
    &__snowImg {
      min-width: 1200px;
      top: -34px;
      left: -33px;

      &--2 {
        min-width: 750px;
        top: -11px;
        left: -1px;
      }

      &--rotated.card__snowImg--2 {
        transform: rotateY(180deg);
        left: 0px;
      }
    }
    &__body {
      flex-direction: column-reverse;
    }
    &__title--desktop {
      display: none;
    }
    &__title--mobile {
      display: flex;
      margin-bottom: 25px;
    }
    &__content {
      width: 100%;
      margin-right: 0;
    }
    &__credit-value {
      font-size: 25px;
    }

    &__button {
      &::after {
        content: none;
      }
    }
  }

  .car {
    &__swiper {
      width: 100%;
    }
  }
}

@media (max-width: 650px) {
  .card {
    padding: 15px;
  }

  .car {
    &__colors {
      gap: 10px;

      &-item {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media (max-width: 470px) {
  .card {
    &__advantage {
      width: 45%;
      font-size: 13px;
      display: flex;
      margin-right: 0;
      flex-direction: column;
      justify-content: center;
      &:nth-of-type(odd) {
        margin-right: 10px;
      }
      &:nth-of-type(even) {
        margin-right: 0;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      br {
        display: none;
      }
    }
  }
}
</style>
