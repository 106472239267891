import $ from "jquery";
// import breakpoints from "../styles/breakpoints.scss";

const breakpoints = {
	desktop: '1200px',
	tablet: '640px',
	mobile: '0'
}

const sortedBreakpoints = Object.entries(breakpoints)
	.map(([key, size]) => ({ key, size: +size.replace(/[^\d]/gi, '') }))
	.sort((lhs, rhs) => {
		return rhs.size - lhs.size
	});

function getCallbackNode(el) {
	if (!el.dataset) {
		return "";
	}

	return el.dataset.hasOwnProperty("callback")
		? el
		: getCallbackNode(el.parentNode);
}

export default {
	data() {
		return {
			device_platform: "desktop",
			device_width: 0
		};
	},
	mounted() {
		let handleResize = () => {
			const initial = sortedBreakpoints;
			let width = initial[0];
			for (let i = 0; i < initial.length; ++i) {
				if ($(window).width() > initial[i].size) {
					width = initial[i];
					break;
				}
			}
			this.device_platform = width.key;
      this.device_width = window.innerWidth;
		};
		$(window).on("resize", handleResize);
		handleResize();
	},
	computed: {},
	methods: {
		getCall(type, event) {
			if (event && !type.text) {
				if (type.deep) {
					type.text = getCallbackNode(event.target).innerHTML;
				} else {
					type.text = event.target.innerHTML;
				}
			}
			this.$emit("getCall", type);
		},
		getScrollWidth() {
			let div = document.createElement('div');
			div.style.overflowY = 'scroll';
			div.style.width = '50px';
			div.style.height = '50px';
			div.style.position = 'absolute';
			div.style.zIndex = '-100';
			// мы должны вставить элемент в документ, иначе размеры будут равны 0
			document.body.append(div);
			let scrollWidth = div.offsetWidth - div.clientWidth;
			div.remove();
			return scrollWidth;
		},
		blockScroll() {
			$("body").addClass("scroll-blocked").css({'width': `calc(100% - ${this.getScrollWidth()}px)`});
		},
		getAgreement() {
			this.$emit("getAgreement");
		},
		unblockScroll() {
			$("body").removeClass("scroll-blocked").css({'width': 'initial'});
		}
	}
};
