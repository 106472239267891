<template>
	<div :class="[device_platform]" class="block-get get modal">
		<div class="blacking" @click="hideSelf"></div>
		<div class="get__container">
			<!-- <div class="get__image">
				<img class="get__img" :src="image" alt="">
			</div> -->
			<div class="get__body">
				<img
					src="../images/utils/close.svg"
					alt="Закрыть"
					class="get__close"
					width="24px"
					height="24px"
					@click="hideSelf"
				/>
				<h3 class="get__title font_type_block-title" v-html="data.title ? data.title : 'Получить предложение'"></h3>
				<a class="get__subtitle" @click="sendMetricGoal" :href="`tel:${phone_raw}`">
					{{ phone }}
				</a>
				<callback-input
					v-on="$listeners"
					:form_class="'popup'"
					:focusOnMount="true"
					:data="data"
				>
					{{data.button ? data.button : 'ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ'}}
				</callback-input>
			</div>

		</div>
	</div>
</template>

<script>
import CallbackInput from "./callback-form/callback-input";
import Mixin from "../common/mixin";
import Cars from "../common/cars-info";
import CONSTANTS from "../common/constants";

export default {
	name: "full-get",
	components: { CallbackInput },
	mixins: [Mixin],
	props: ["data"],
	data() {
		return {
			phone: CONSTANTS.phone,
			phone_raw: CONSTANTS.phone_raw,
		};
	},
	// computed: {
	// 	image() {
	// 		if (this.data.img && this.images[this.data.img]) {

	// 			return this.images[this.data.img];
	// 		}
	// 		return this.images[this.defaultImg];
	// 	}
	// },
	methods: {
		hideSelf() {
			this.$emit("close");
		},
		getAgreement() {
			this.$emit("getAgreement");
		},
        sendMetricGoal(){
            if(ym){
                ym(8467762,'reachGoal','click_number');
            }
        },
	},
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.get {
	display: flex;

	&__container {
		position: fixed;
		top: 50vh;
		left: 50vw;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: 420px;
		display: flex;
		justify-content: center;
		z-index: 500;
		pointer-events: none;
	}

	&__title {
		font-size: 22px;
		text-align: center;
		line-height: 120%;
		margin-bottom: 30px;
		text-transform: uppercase;
	}
	&__subtitle {
		display: block;
		text-align: center;
		font-size: 22px;
		line-height: 120%;
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 20px;
	}
	&__body {
		position: relative;
		// padding: 26px 70px 26px 20px;
		padding: 44px;
		padding-right: 60px;
		background: #fff;
		pointer-events: all;
	}
	&__image {
		pointer-events: all;
		display: flex;
	}

	&__img {
		max-width: 100%;
		max-height: 100%;
		object-fit: cover;
	}

	&__close {
		position: absolute;
		right: 18px;
		top: 20px;
		cursor: pointer;
	}
}

@media (max-width: $tablet-md) and (min-height: 550px) {
	.get {
		&__container {
			flex-direction: column;
			align-items: stretch;
			max-width: 400px;
		}
		&__body {
			max-width: 100%;
		}
		&__image {
			flex-shrink: 1;
		}
	}
}
@media (max-width: $mobile-md) {
	.get {

		&__container {
			top: 47vh;
			flex-direction: column;
			align-items: stretch;
			max-width: 400px;
		}
		&__body {
			max-width: 100%;
			padding-right: 40px;
		}
		&__image {
			flex-shrink: 1;
			max-height: 35vh;
		}
		&__close {
			top: 12px;
			right: 10px;
		}
	}
}

@media (max-width: $mobile-sm) {
	.get {
		&__container {
			max-width: calc(100vw - 16px);
		}
	}
}

@media (max-height: 550px) {
	.get {
		&__body {
			padding-right: 40px;
		}
		&__close {
			top: 12px;
			right: 10px;
		}
	}
}

</style>
