<template>
  <div @click="closeByOutside" class='full-gallery'>
    <div class="full-gallery__modal">

      <div class="full-gallery__close" @click="close">&#9587;</div>

      <div ref="swiper" class="swiper">
        <div class="swiper-wrapper">
          <div
            v-for="(img, i) in imgs" :key="`slide-${i}`"
            class="swiper-slide"
          >
            <img :data-src="img" class="swiper-img swiper-lazy">
          </div>
        </div>

        <div class="swiper-button-prev swiper-button"></div>
        <div class="swiper-button-next swiper-button"></div>

        <div class="swiper-pagination swiper-pagination--gallery"></div>
      </div>

    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper'
import {Lazy} from "swiper/dist/js/swiper.esm";

export default {
  name: 'full-gallery',
  emits: ["close"],
  components: {},
  props: {
		imgs: Array,
    index: Number,
  },
  data(){
    return{
    }
  },
  methods:{
    close(){
      this.$emit('close')
    },
    closeByOutside(e) {
      if(e.target == e.currentTarget)
        this.close()
    },
    closeByEsc(e) {
      if(e.code == 'Escape')
        this.close()
    },
  },
  mounted() {
    document.addEventListener('keydown', this.closeByEsc)
		let index = this.index
    const swiper = new Swiper(this.$refs.swiper, {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination--gallery',
      },
      grabCursor: true,
			preloadimages: false,
      lazy: {
        loadOnTransitionStart: false,
        loadPrevNext: false,
      },

      modules: [Navigation, Pagination, Lazy],

      on: {
        init(){
          this.slideTo(index) // проматываем к нужному слайду
        },
      },
    })
  },

  destroyed() {
    document.removeEventListener('keydown', this.closeByEsc)
  },
};
</script>

<style lang='scss' scoped>
@import "@/styles/constants.scss";

.full-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.62);

  &__modal {
    position: relative;
    width: 800px;
    max-width: 90vw;
    height: 600px;
    max-height: 90vh;

    @media (orientation: portrait) {
      //max-height: calc(3 / 4 * 100vw);
      max-height: 100vw;
    }
  }

  &__slider {
  }

  &__slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    right: 10px;
    top: 0px;
    width: 30px;
    height: 30px;
    font-size: 30px;
		font-weight: bold;
    color: red;
    cursor: pointer;
    z-index: 200;
    transition: 0.3s;

    &:hover{
			scale: 1.05;
			text-shadow: 1px 1px 2px #000;
    }

    @media (max-width: 640px) {
      font-size: 22px;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-slide {
    width: 100%;
    height: 100%;
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-button{
    color: $primary;
    text-shadow: 1px 1px 1px #000;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23f02'%2F%3E%3C%2Fsvg%3E");
		transition: 0.3s;

		&-next {
			rotate: 180deg;
		}

    &:hover{
			scale: 1.05;
		}

    &:active{
			scale: 0.98;
			transition: 0.1s;
		}

    @media (max-width: 640px) {
      &:after{
        font-size: 32px;
      }
      &-prev{
        left: 0px;
      }
      &-next{
        right: 0px;
      }
    }

  }

	&-pagination--gallery {
		&::v-deep .swiper-pagination-bullet-active {
			background-color: red;
		}
	}
}

</style>
