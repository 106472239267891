<template>
  <section class="block-cars cars container" :class="[device_platform]">
    <!-- Навигация слева страницы -->
    <!-- <nav class="cars__navigation">
      <button class="cars__nav-button"
        v-for="model in models"
        :key="model"
        @click="scrollTo(model)"
      >
        <span class="cars__nav-text">
          {{ model.toUpperCase() }}
        </span>
      </button>
    </nav> -->

    <!-- Навигация внутри блока -->
    <nav class="navigation">
      <div class="navigation__body">
        <!-- Тип машины -->
        <ul class="navigation__type-list">
          <li
            class="navigation__type-item"
            v-for="type in types"
            :key="type.text"
          >
            <button
              class="navigation__type-button"
              @click="filterNavigation(type)"
            >
              {{ type.text }}
            </button>
          </li>
        </ul>

        <!-- Модели -->
        <ul class="navigation__model-list">
          <li
            class="navigation__model-item"
            v-for="model in models"
            :key="model"
          >
            <button class="navigation__model-button" @click="scrollTo(model)">
              {{ model.toUpperCase().replace('_', ' ') }}
            </button>
          </li>
        </ul>
      </div>
      <button
        class="navigation__button button button_theme_primary"
        @click="scrollTo('stock')"
      >
        Спец. цены на а/м в наличии
      </button>
    </nav>

    <card-new
      v-for="car in newCars"
      :key="car.model"
      :car="car"
      v-on="$listeners"
    />

    <card
      class="cars__card"
      v-for="(carsD, idx) in desktopCars"
      :key="carsD[0].complectation + idx"
      :cars="carsD"
      v-on="$listeners"
      @toggle-car="changeCarInPopup"
      :amounts="amounts"
      :snowImgNum="getSnowImgIndex()"
    />

    <!-- Попап внизу страницы -->
    <div class="popup" v-if="popup && active_car">
      <button class="popup__button-close" @click="closePopup"></button>
      <div class="popup__body">
        <div class="popup__car">
          <img
            class="popup__car-image"
            :src="active_car.img"
            :alt="active_car.model"
          />
        </div>
        <div class="popup__content">
          <h3 class="popup__title">
            Lexus {{ active_car.model.toUpperCase() }}
            {{ active_car.complectation }}
          </h3>
          <p class="popup__price">
            от {{ active_car.month_payment | finance }} руб. в месяц
          </p>
        </div>
      </div>

      <button
        class="popup__button"
        @click="getCall({ type: 'sale', form: 'cars' })"
      >
        Забронировать
      </button>
    </div>
  </section>
</template>

<script>
import Mixin from "../common/mixin";
import CarsInfo from "../common/cars-info";
import Card from "../components/card";
import CardNew from "../components/card-new";
import finance from "../common/finance";

export default {
  name: "block-cars",
  mixins: [Mixin, finance],
  props: ["amounts"],
  components: { Card, CardNew },
  data() {
    return {
      cars: CarsInfo.CARS,
      models: ["rx", "lx", /*"rx_new",*/ "es",/* "nx",*/ "gx", "tx", "rx_v"],
      types: [
        { text: "Все", models: ["rx", "lx", /*"rx_new",*/ "es",/* "nx",*/ "gx", "tx", "rx_v"] },
        { text: "Внедорожники", models: ["lx", "gx", "tx"] },
        { text: "Кроссоверы", models: ["rx", "rx_v"/*, "rx_new"*/ /*, "nx"*/] },
        { text: "Седаны", models: ["es"] },
      ],
      popup: true,
      active_car: null,
    };
  },
  computed: {
    desktopCars() {
      const cars = this.cars
        .filter((car) => !car.new)
        .reduce((total, car) => {
          const model = car.model;
          if (!total[model]) {
            total[model] = [];
          }
          total[model].push(car);
          return total;
        }, {});
      console.log(cars)
      return Object.values(cars);
    },
    newCars() {
      return this.cars.filter((car) => car.new);
    },
  },
  methods: {
    filterNavigation({ models }) {
      this.models = models;
      this.cars = CarsInfo.CARS.filter((car) => models.includes(car.model));
    },
    changeCarInPopup(car) {
      this.active_car = car;
      this.popup = true;
    },
    closePopup() {
      this.popup = false;
    },
    scrollTo(where) {
      this.$emit("scrollTo", where);
    },
    getSnowImgIndex(){
      // Случайным образом выбирает тип картинки снега либо без неё
      return Math.floor( Math.random() * 3)
    }
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.cars {
  width: 100%;
  padding-top: 40px;
  &__card {
    margin-bottom: 30px;
  }
  &__navigation {
    position: fixed;
    top: 100px;
    left: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    padding: 10px;
    background: #ffffff;
    z-index: 10;
    &.active {
      display: flex;
    }
  }
  &__nav-button {
    display: block;
    color: #fff;
    font-weight: 700;
    background: transparent;
    color: black;
    margin-bottom: 20px;
    border: none;
    width: 60px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    outline: none;
    &:hover {
      border-color: black;
    }
  }
  &__nav-text {
    line-height: 45px;
    font-size: 35px;
    display: block;
  }
}

.navigation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  &__body {
    width: 50%;
  }
  &__type-list,
  &__model-list {
    display: flex;
    list-style-type: none;
    margin-bottom: 5px;
  }
  &__type-item {
    padding: 2px 5px;
    margin-left: 10px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    &:hover {
      border-top-color: black;
      border-bottom-color: black;
    }
  }
  &__type-button {
    font-family: "Favorit Light", sans-serif;
    border: none;
    background: none;
    font-weight: 300;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
  }
  &__model-item {
    margin-left: 10px;
    padding: 0 5px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    &:hover {
      border-top-color: black;
      border-bottom-color: black;
    }
  }
  &__model-button {
    font-size: 24px;
    border: none;
    background: none;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
  }
  &__button {
    width: 334px;
    height: 50px;
  }
}

.popup {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  padding: 10px;
  background: gray;
  z-index: 50;

  &__button-close {
    content: "";
    position: absolute;
    top: 8px;
    right: -5px;
    width: 30px;
    height: 30px;
    background: url("../images/utils/close-white.svg") no-repeat;
    border: none;
  }
  &__body {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    padding-right: 15px;
  }
  &__car {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  &__car-image {
    width: 100%;
  }
  &__content {
    font-size: 16px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__button {
    padding: 7px;
    border: none;
    border-radius: 3px;
    text-transform: uppercase;
    background-color: white;
    width: 100%;
    font-size: 16px;
  }
}

@media (max-width: 820px) {
  .navigation {
    flex-direction: column;
    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin-bottom: 15px;
    }
    &__type-list,
    &__model-list {
      flex-wrap: wrap;
    }
    &__button {
      width: 85%;
    }
    &__button::after {
      content: none;
    }
  }
  .cars {
    &__navigation {
      display: none;
    }
  }
}

@media (max-width: 580px) {
  .cars__card {
    margin-bottom: 50px;
  }
}

@media (max-width: 500px) {
  .popup {
    display: flex;
  }
}
</style>
