<template>
  <header class="block-header header" :class="device_platform">
    <div class="header__container container">
      <!-- <img v-if="device_platform !== 'mobile'" src="@/images/temporary/garnish.png" alt="" class="header__img"> -->
      <div class="header__logos-wrapper">
        <a class="header__brand-logo" @click="getCall({ type: 'sale', form: 'header', title: 'Заказать звонок' })">
          <img src="../images/logos/logo.svg" alt="Логотип Lexus" />
        </a>

        <!-- <img v-if="device_platform == 'mobile'" src="@/images/temporary/garnish.png" alt="" class="header__img header__img-mobile"> -->

        <a class="header__dealer-logo" @click="getCall({ type: 'sale', form: 'header', title: 'Заказать звонок' })">
          <!-- РОЛЬФ Волгоградский -->
          <img
            src="../images/logos/logo.png"
            alt="Логотип Рольф Ясенево"
          />
          <p class="header__address">Официальный дилер</p>
        </a>
        <!-- <img
          v-if="device_platform === 'desktop'"
          src="../images/logos/logo-award-2022.svg"
          alt="Автодилер года 2021"
          class="header__logo-award"
          @click="getCall({ type: 'sale', form: 'header', title: 'Заказать звонок' })"
        /> -->
      </div>
      <!-- <p class="header__address">
        Официальный дилер<br />
        LEXUS в Москве
      </p> -->
      <span class="call_phone_4">
        <a class="header__phone top_phone" @click="sendMetricGoal" :href="`tel:${CONSTANTS.phone_raw}`">
          {{ CONSTANTS.phone }}
        </a>
      </span>
      <div class="header__buttons-wrapper">
        <button
          class="header__button button button_theme_primary"
          @click="getCall({ type: 'sale', form: 'header', title: 'Заказать звонок' })"
        >
          Заказать звонок
        </button>
        <a class="header__call-button" @click="sendMetricGoal" :href="`tel:${CONSTANTS.phone_raw}`"></a>
      </div>

      <!-- <img v-if="device_platform !== 'mobile'" src="@/images/temporary/garnish.png" alt="" class="header__img header__img--right"> -->

      <!-- <iframe
        class="header__badge"
        src="https://yandex.ru/sprav/widget/rating-badge/1268024858"
        width="150"
        height="50"
        frameborder="0"
      ></iframe> -->

      <!-- <a class="header__inst" href="https://www.instagram.com/rolf_volgogradka/?utm_medium=copy_link" target="_blank">
        <img src="../images/utils/instagram.png" alt="Instagram Logo">
      </a> -->

    </div>
  </header>
</template>

<script>
import Mixin from "../common/mixin";
import $ from "jquery";
import CONSTANTS from "../common/constants";

export default {
  name: "block-header",
  mixins: [Mixin],
  data() {
    return {
      CONSTANTS,
    };
  },
  mounted() {
    $("html").on("keyup", (event) => {
      if (event.keyCode === 27) {
        this.show.creq = false;
      }
    });
    $(window).on("scroll", () => {
      this.is_scrolled = window.scrollY;
    });
  },
  methods: {
    getAgreement() {
      this.$emit("getAgreement");
    },
    sendMetricGoal(){
      if(ym){
        ym(8467762,'reachGoal','click_number');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.header {
  width: 100%;
  z-index: 20;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px 10px;
  }

  &__img {
    position: absolute;
    right: 86%;

    &--right {
      left: 85%;
    }
  }

  &__logos-wrapper {
    display: flex;
    align-items: center;
  }
  &__brand-logo {
    cursor: pointer;
    width: auto;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px dashed #000;
    img {
      width: 180px;
    }
  }
  &__dealer-logo {
    cursor: pointer;
    // font-size: 22px;
    // line-height: 22px;
    width: 200px;
    margin-right: 15px;

    img {
      width: 100%;
    }
  }
  // &__logo-award {
  //   cursor: pointer;
  //   max-width: 120px;
  //   margin-right: 15px;
  // }
  &__address {  
    font-family: "Favorit Light", sans-serif;
    font-weight: 300;
    // width: 160px;
  }
  &__buttons-wrapper {
    // width: 22%;
    margin-left: 20px;
    margin-right: 10px;
  }
  &__button {
    height: 50px;
    width: 230px;
    // max-width: 230px;
  }
  &__call-button {
    display: none;
  }
  &__badge {
    width: 150px;
    height: 50px;
    margin-left: 10px;
    margin-right: 20px;
    pointer-events: none;
  }
  &__inst {
    display: flex;
    width: 30px;
    & img {
      width: 100%;
    }
  }
}

@media (max-width: 1120px) {
  .header {
    &__badge,
    &__address {
      display: none;
    }
    &__phone {
      font-size: 14px;
    }
    &__button {
      padding: 0 22px;
      text-align: center;
      &::after {
        content: none;
      }
    }
  }
}

@media (max-width: 760px) {
  .header {
    &__container {
      flex-direction: column;
    }

    &__img {
      width: 27vw;
      top: 0;
      right: auto;
      left: 37%;
    }

    &__logos-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;
    }
    &__brand-logo {
      margin-right: 0;
      border-right: none;
      img {
        width: 130px;
      }
    }
    &__dealer-logo {
      margin-right: 0;
      width: auto;
      img {
        width: 130px;
      }
    }
    &__phone {
      margin-bottom: 15px;
    }
    &__buttons-wrapper {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 0;
    }
    &__button {
      width: 78%;
    }
    &__call-button {
      width: 70px;
      height: 50px;
      display: block;
      background: black;
      color: white;
      background: url("../images/utils/phone.svg") no-repeat;
      background-size: 35px;
      background-position: center;
      background-color: black;
    }
    &__inst {
      display: none;
    }
  }
}

@media (max-width: 660px) {
  .header {
    position: fixed;
    z-index: 20;
    background: white;
    &__container {
      padding-top: 10px;
    }
    &__logos-wrapper {
      margin-bottom: 10px;
    }
    &__phone {
      display: none;
    }
  }
}
</style>
