<template>
	<ul class="selector">
		<li class="selector__item" v-for="item in data" :key="item">
			<button
				class="selector__btn"
				:class="{ active: item === value }"
				@click="changeValue(item)"
			>
				{{ item }}
			</button>
		</li>
	</ul>
</template>

<script>
	export default {
		name: "selector",
		props: {
			data: {
				type: Array,
				default: [],
			},
			value: String,
		},
		emits: ["update:value"],
		methods: {
			changeValue(value) {
				this.$emit("change", value);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "../styles/constants.scss";
	.selector {
		width: 100%;
		list-style: none;
		display: flex;
		align-items: center;

		&__item {
			flex: 1;
		}

		&__btn {
			width: 100%;
			font-weight: bold;
			font-size: 16px;
			text-transform: uppercase;
			white-space: nowrap;
			border: none;
			background-color: #fff;
			cursor: pointer;
			padding: 28px 5px;
			transition: background-color 0.2s ease-in;

			&.active {
				// background-color: #e6e3e3;
				background: #000;
				color:#fff;
				transition: background-color 0.2s ease-out;
			}
		}
	}
</style>
